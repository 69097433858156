import React from "react";
import { translate, toast, functions } from "../../../lib";
import { ComponentLoader } from "../../../components";
import { Asset } from "./";
import { protocolFiles, showModal, hideModal } from "../../../actions";
import $ from "jquery";

export class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      show_info_img: false,

      fileSrc: null,
      isVideo: false,
      showFiles: false,
      modals: [],

      fin: "1234567",
      protocol_id: props.protocolID,
      response: null
    };

    $(document).on("click", e => {
      if (
        !$(".custom__modal").is(e.target) &&
        $(".custom__modal").has(e.target).length === 0 &&
        !$(".asset_modal").is(e.target) &&
        $(".asset_modal").has(e.target).length === 0
      ) {
        this.onClose();
      } else if (
        !$(".info-btn").is(e.target) &&
        $(".info-btn").has(e.target).length === 0
      ) {
        this.setState({ show_info_img: false });
      }
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let { fin, protocol_id } = this.state;
    let params = { fin, protocol_id };

    let response = await protocolFiles(params);

    if (response) {
      this.setState({ isLoading: false });

      if (response.status === "success") {
        this.setState({ response: response, showFiles: true });
      } else {
        toast.fire({
          icon: response.status === "success" ? "success" : "error",
          title: response.description
        });
      }
    }
  };

  async componentDidMount() {
    let { fin, protocol_id } = this.state;
    let params = { fin, protocol_id };

    let response = await protocolFiles(params);

    if (response) {
      this.setState({ isLoading: false });

      if (response.status === "success") {
        this.setState({ response: response, showFiles: true });
      } else {
        toast.fire({
          icon: response.status === "success" ? "success" : "error",
          title: response.description
        });
        this.onClose();
      }
    }
  }

  render() {
    let {
      isLoading,
      response,
      fin,
      show_info_img,
      showFiles,
      modals,
      fileSrc,
      isVideo
    } = this.state;

    return (
      <>
        {functions.in_array("asset", modals) && (
          <Asset
            fileSrc={fileSrc}
            video={isVideo}
            onClose={() => {
              hideModal.bind(this)("asset");
            }}
          />
        )}
        <div className="custom__modal-container">
          <div className={`custom__modal ${showFiles ? "bg_modal" : ""}`}>
            {isLoading ? (
              <ComponentLoader />
            ) : (
              <>
                <div className="custom__modal-header">
                  <div className="modal__title">
                    {translate.get(
                      showFiles ? "Şəkillər və Videolar" : "Tesdiq"
                    )}
                  </div>
                  <button
                    className="close-btn feather feather-x"
                    onClick={this.onClose}
                  ></button>
                </div>
                <div className="custom__modal-body">
                  {showFiles ? (
                    <div className="protocol-file__content">
                      <p className="container-header">
                        {translate.get("Şəkillər")}
                      </p>
                      <div className="custom-row item-container mb-4">
                        {response &&
                          response.data.map((item, i) => (
                            <div
                              className="custom-col-md-25 custom-col-6"
                              key={i}
                            >
                              <div
                                className="protocol-file-item"
                                onClick={async () => {
                                  await this.setState({
                                    fileSrc: item.src,
                                    isVideo: false
                                  });
                                  showModal.bind(this)("asset");
                                }}
                              >
                                <img alt="" src={item.src} />
                              </div>
                            </div>
                          ))}
                      </div>

                      <p className="container-header">
                        {translate.get("Videolar")}
                      </p>
                      <div className="custom-row item-container mb-4">
                        {response && response.video ? (
                          <div className="custom-col-md-25 custom-col-6">
                            <div
                              className="protocol-file-item"
                              onClick={() => {
                                this.setState({
                                  fileSrc: response.video.url,
                                  isVideo: true
                                });
                                showModal.bind(this)("asset");
                              }}
                            >
                              <img alt="" src={response.video.video} />
                            </div>
                          </div>
                        ) : (
                          <p className="text-center w-100">
                            {translate.get("Video yoxdur")}
                          </p>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className="custom-alert success alert alert-success"
                        role="alert"
                      >
                        <i className="feather feather-info"></i>Cərimə olunan
                        vətəndaşın şəxsiyyət vəsiqəsindəki FİN kodu daxil edin.
                        Yalnız fiziki şəxslərə yazılan EQ və BNA-ların
                        şəkillərinə baxa bilərsiz.
                      </div>
                      <form onSubmit={this.onSubmit}>
                        <div className="row">
                          <div className="col-md-12 mb-4">
                            <label className="custom-label">
                              {translate.get("Fin kodu daxil edin")}
                            </label>
                            <button
                              type="button"
                              className="info-btn feather feather-help-circle"
                              onClick={() => {
                                let prev = this.state.show_info_img;
                                this.setState({
                                  show_info_img: !prev
                                });
                              }}
                            >
                              {show_info_img ? (
                                <div className="info__image">
                                  <img
                                    alt=""
                                    src="/assets/images/passport_example.svg"
                                  />
                                </div>
                              ) : null}
                            </button>
                            <input
                              autoFocus
                              required
                              maxLength="7"
                              type="text"
                              className="custom-input"
                              placeholder={"1234567"}
                              value={fin}
                              onChange={e => {
                                this.setState({ fin: e.target.value });
                              }}
                            />
                          </div>
                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="custom-btn br w-100"
                            >
                              {translate.get("Yoxla")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
