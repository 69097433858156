import React from "react";
import { Link } from "react-router-dom";
import { translate, user, functions, globalState } from "../../lib";
import { hideModal } from "../../actions";
import { Modal } from "./components";
import { ComponentHeader } from "../../components/Header";
import $ from "jquery";

export class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    var permission = globalState.getData()?.permissions;
    this.state = {
      modals: [],
      links: [
        {
          path: "/protocol",
          tColor: "#9A5FF7",
          bColor: "#7D22E4",
          icon: "feather-sun",
          image: "alert",
          text: "Cərimələr",
          permissions: true,
        },
        {
          path: "/cars",
          tColor: "#FF8373",
          bColor: "#E43768",
          icon: "feather-navigation",
          image: "car",
          text: "Avtomobillərim",
          permissions: true,
        },
        {
          path: "/history",
          tColor: "#7FA0FF",
          bColor: "#6351E9",
          icon: "feather-calendar",
          image: "history",
          text: "Ödəniş tarixçəsi",
          permissions: true,
        },

        {
          path: "/point",
          tColor: "#FDC600",
          bColor: "#FF8756",
          icon: "feather-smartphone",
          image: "tablet",
          text: "Balı yoxla",
          permissions: true,
        },
        {
          path: "/licenses",
          tColor: "#59E7E0",
          bColor: "#1AB7E0",
          icon: "feather-list",
          image: "passport",
          text: "Sürücülük vəsiqələrim",
          permissions: true,
        },
        {
          path: "/protocol/pay",
          tColor: "#A3AEBF",
          bColor: "#77869E",
          icon: "feather-file-text",
          image: "card",
          text: "Cərimə ödə",
          permissions: true,
        },
        {
          path: "/faq",
          tColor: "#7FA0FF",
          bColor: "#6351E9",
          icon: "feather-message-circle",
          image: "chat",
          text: "Sual-Cavab",
          permissions: true,
        },
        {
          path: "/exam",
          tColor: "#58EBAB",
          bColor: "#36C587",
          icon: "feather-check-circle",
          image: "exam",
          text: "Onlayn İmtahan",
          // badge: (<span className="dash__link-badge badge">Yeni</span>),
          permissions: true,
        },
        {
          path: "/law",
          tColor: "#FDC600",
          bColor: "#FF8756",
          icon: "feather-shield",
          image: "law",
          text: "Qanunvericilik",
          permissions: true,
        },
        {
          path: "/insurance/info",
          tColor: "#A3AEBF",
          bColor: "#77869E",
          icon: "feather-umbrella",
          image: "umbrella",
          text: "Sığorta xidməti",
          permissions: true,
        },
        {
          path: "/insurance",
          tColor: "#FF8373",
          bColor: "#E43768",
          icon: "feather-book",
          image: "menu",
          text: "İcbari Sığorta",
          permissions: true,
        },
        {
          path: "/balance",
          tColor: "#58EBAB",
          bColor: "#36C587",
          icon: "feather-plus-circle",
          image: "balance",
          text: "Balansı artır",
          // balance: user.getData().balance,
          permissions: permission?.balance_view?.allow,
        },
        {
          path: "/cards",
          tColor: "#FEC502",
          bColor: "#FF8B51",
          icon: "feather-refresh-cw",
          image: "cards",
          text: "Kartlarım",
          permissions: permission?.cards_view?.allow,
        },
        {
          path: "/number",
          tColor: "#14D2B8",
          bColor: "#2DC9EB",
          icon: "feather-search",
          image: "phone",
          text: "Nömrəni dəyiş",
          permissions: true,
        },
        {
          path: "/password",
          tColor: "#7FA0FF",
          bColor: "#6351E9",
          icon: "feather-refresh-cw",
          image: "lock",
          text: "Şifrəni dəyiş",
          permissions: true,
        },
        // {
        //   tColor: "#FF8373",
        //   bColor: "#E43768",
        //   icon: "feather-user-plus",
        //   image: "friend",
        //   text: "Dostunu dəvət et",
        //   action: () => {
        //     showModal.bind(this)("share");
        //   }
        // },
        // {
        //   tColor: "#FF8373",
        //   bColor: "#E43768",
        //   icon: "feather-log-out",
        //   image: "logout",
        //   text: "Çıxış",
        //   action: () => {
        //     user.logOut();
        //   }
        // },
      ],

      screenWidth: window.innerWidth,
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }

  render() {
    let { links, modals, screenWidth } = this.state;

    return (
      <div>
        {screenWidth < 1025 ? (
          <ComponentHeader msg={true} logout={true} operator={true} />
        ) : null}
        <div className="Dashboard">
          {functions.in_array("share", modals) ? (
            <Modal
              onClose={() => {
                hideModal.bind(this)("share");
              }}
            />
          ) : null}
          {links.map(
            (link, i) =>
              link.permissions && (
                <div className="custom-col-md-20 custom-col-4" key={i}>
                  <Link
                    target={link.blank ? "blank" : ""}
                    to={{ pathname: link.path }}
                    className="dash__link-item"
                    onClick={link.action}
                  >
                    <div
                      className="dash__link-icon"
                      style={{
                        background: `linear-gradient(${link.tColor}, ${link.bColor})`,
                      }}
                    >
                      {link.badge && link.badge}
                      {link.image ? (
                        <img alt="" src={`/assets/images/${link.image}.svg`} />
                      ) : (
                        <i className={`feather ${link.icon}`}></i>
                      )}
                    </div>
                    <div className="dash__link-text">
                      <p>{translate.get(link.text)}</p>
                    </div>
                    {link.balance ? (
                      <div className="dash__link-balance">
                        <p>{`(${link.balance} AZN)`}</p>
                      </div>
                    ) : null}
                  </Link>
                </div>
              )
          )}
          {/* <div className="custom-col-md-20 custom-col-4">
              <button
                onClick={() => {
                  user.logOut();
                }}
                className="dash__link-item"
              >
                <div
                  className="dash__link-icon"
                  style={{
                    background: `linear-gradient(#FDC600, #FF8756)`
                  }}
                >
                  <img alt="" src="/assets/images/exit.svg" />
                </div>
                <div className="dash__link-text">
                  <p>{translate.get("Çıxış")}</p>
                </div>
              </button>
            </div> */}
        </div>
      </div>
    );
  }
}
