import { functions, globalState, translate } from ".";
import Swal from 'sweetalert2';
import {accountLogout} from '../actions';


export const user = {
  setData(data) {
    globalState.set({
      account_data: data
    });
  },
  getData() {
    return globalState.get("account_data") || {};
  },
  getPermission(key) {
    return (this.getData() && this.getData().permissions[key]) || false;
  },

  isPermitted(section, type, selected = false) {
    let permission =
      (this.getData() && this.getData().permissions[section + "_" + type]) ||
      false;
    if (selected) {
      let selecteds = (permission && permission.selected) || [];
      return functions.in_array(selected, selecteds) ? true : false;
    } else {
      return (permission && permission.allow) || false;
    }
  },
  isAuth() {
    return this.getData().id ? true : false;
  },
  logOut() {
    Swal.fire({
      title: null,
      text: translate.get("Çıxmaq istədiyinizdən əminsinizmi?"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Bəli"),
      cancelButtonText: translate.get("Xeyr")
    }).then(async res => {
      if (res && res.value) {
        await accountLogout()
        window.location = "/";
      }
    });
  }
};
