import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ComponentLoader, ComponentHeader, Sidebar } from "../../components";
import { Modal } from "./components";
import {
  translate,
  globalFunctions,
  functions,
  toast,
} from "../../lib";
import { carsList, historyList, showModal, hideModal } from "../../actions";
import $ from "jquery";

export class HistoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isScrollLoading: false,
      isContentLoading: false,
      isSearchLoading: false,

      modals: [],

      keyword: "",
      limit: 50,
      offset: 0,

      count: 0,
      cars: [],
      car: null,

      protocols: [],
      protocolText: "",
      protocolID: null,

      screenWidth: window.innerWidth,
    };

    globalFunctions.set({
      getCarInfo: () => {
        return this.state.car;
      },
    });

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }

  reLoad = async () => {
    this.setState({ isLoading: true });

    let { limit, offset } = this.state;
    let params = { limit, offset };
    let cars = await carsList(params);
    if (cars) {
      this.setState({ isLoading: false });
      if (cars.status === "success") {
        this.setState({ cars: cars.data, count: cars.count });
      } else {
        this.setState({ cars: [] });
      }
    }
  };

  componentDidMount() {
    this.reLoad();
  }

  onGet = async (data) => {
    if (data) {
      if (data !== this.state.car) {
        this.setState({ car: data, isContentLoading: true });
        let response = await historyList({ car_number: data.car_number });
        if (response) {
          this.setState({ isContentLoading: false });
          if (response.status === "success") {
            this.setState({ protocols: response.data, protocolText: "" });
          } else {
            this.setState({
              protocolText: response.description,
              protocols: [],
            });
          }
          let { protocols, protocolText } = this.state;
          globalFunctions._setSidebarState({ protocols, protocolText });
        }
      }
    } else {
      this.setState({ car: null });
    }
  };

  onScroll = async () => {
    let elmnt = document.querySelector(".sidebar__item-container");
    let scrollableHeight = elmnt.scrollHeight - elmnt.clientHeight;
    let percent = ((elmnt.scrollTop / scrollableHeight) * 100).toFixed();

    if (
      !this.state.isScrollLoading &&
      percent >= 100 &&
      this.state.offset + this.state.limit <= this.state.count
    ) {
      let skip = this.state.offset;
      await this.setState({ offset: (skip += 50) });

      this.setState({ isScrollLoading: true });
      let { limit, offset } = this.state;
      let params = { limit, offset };
      let response = await carsList(params);
      if (response) {
        this.setState({ isScrollLoading: false });
        if (response.status === "success") {
          let cars = this.state.cars;
          this.setState({ cars: [...cars, ...response.data] });
        } else {
          toast.fire({
            icon: "error",
            title: response.description,
          });
        }
      }
    }
  };

  onSearch = async (e) => {
    if (e.target.value.trim().length >= 5) {
      await this.setState({ keyword: e.target.value });

      this.setState({ isSearchLoading: true });
      let { keyword, limit } = this.state;
      let params = { keyword, limit };
      let cars = await carsList(params);
      if (cars) {
        this.setState({ isSearchLoading: false });
        if (cars.status === "success") {
          this.setState({ cars: cars.data, count: cars.count });
        } else {
          this.setState({ cars: [], count: 0 });
          toast.fire({
            toast: true,
            position: "top",
            icon: "error",
            title: cars.description,
            timer: 1000,
          });
        }
      }
    } else if (e.target.value.trim().length === 0) {
      await this.setState({ keyword: "" });

      this.reLoad();
    }
  };

  render() {
    let {
      isLoading,
      isScrollLoading,
      isContentLoading,
      isSearchLoading,
      modals,
      cars,
      protocols,
      protocolText,
      protocolID,
      screenWidth,
    } = this.state;

    return (
      <>
        <div className='content' >
          <ComponentHeader
              title={translate.get("Ödəniş Tarixçəsi")}
              back={'/'}
              onSearch={this.onSearch}
              searchable={true}
              placeholder='Axtarın'
              msg={true}
              grid={true}
          />
        {isLoading ? (
          <ComponentLoader />
        ) : (
            <>
          <div className="Protocols">
            {functions.in_array("confirm", modals) ? (
              <Modal
                protocolID={protocolID}
                onClose={() => {
                  hideModal.bind(this)("confirm");
                }}
              />
            ) : null}
            <div className="protocols__content">
              <Sidebar
                searchable={true}
                data={cars}
                type={"history"}
                onGet={this.onGet}
                onShow={() => {
                  showModal.bind(this)("confirm");
                }}
                setID={(id) => {
                  this.setState({ protocolID: id });
                }}
                onSearch={this.onSearch}
                onScroll={this.onScroll}
                isScrollLoading={isScrollLoading}
                isContentLoading={isContentLoading}
                isSearchLoading={isSearchLoading}
              />
              {screenWidth > 1024 ? (
                  <div className='protocols__container' >
                    <div className="protocols__content-main">
                      {isContentLoading ? (
                        <ComponentLoader />
                      ) : protocols.length ? (
                        <ContentHistory
                          object={protocols}
                          setID={(id) => {
                            this.setState({ protocolID: id });
                          }}
                          onShow={() => {
                            showModal.bind(this)("confirm");
                          }}
                        />
                      ) : protocolText ? (
                        <div className="protocol__empty-content">
                          <img alt="" src="/assets/images/alert-big.svg" />
                          <p className="protocol__empty-content-text">
                            {protocolText}
                          </p>
                        </div>
                      ) : cars.length === 0 ? (
                          <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                            <img src='/assets/images/noCar.svg' alt='' />
                            <p style={{ color:'#16A085', margin:'2em 0' }} >Avtomobil əlavə olunmayıb..</p>
                          </div>
                      ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                          <img src='/assets/images/noCar.svg' alt='' />
                          <p style={{ color:'#16A085', margin:'1em 0' }} >Avtomobil seçin</p>
                        </div>
                      )}
                    </div>
                  </div>
              ) :
                  cars.length === 0 ? (
                      <div className='protocols__container' >
                        <div className="protocols__content-main">
                          <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-3">
                            <img src='/assets/images/noCar.svg' alt='' style={{ objectFit:'contain', width: screenWidth < 359 ? '100%': '' }} />
                            <p style={{color: '#16A085', margin: '2em 0', textAlign:'center'}}>Avtomobil əlavə olunmayıb..</p>
                            <Link to='/cars/add'>
                              <button className='custom-btn '>Avtomobil əlavə et</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                  ) : null
              }
            </div>
          </div>
            </>
        )}
        </div>
      </>
    );
  }
}

export const ContentHistory = (props) => {
  return (
    <>
      {props.object.map((item, i) => (
        <Fragment key={i}>
          {item.protocol_number ||
          item.fullname ||
          item.amount ||
          item.discount ||
          item.total ||
          item.has_decision ||
          item.speed_max ||
          item.speed_real ||
          item.action_date ||
          item.decision_date ||
          item.payment_date ||
          item.address ||
          item.law_item ? (
            <div className="custom-row py-4">
              {item.protocol_number ? (
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Protokol:")}</p>
                  <p className="data-content">
                    {item.protocol_series + item.protocol_number}
                  </p>
                </div>
              ) : null}

              {item.fullname.trim().length ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("Cərimə olunan:")}
                  </p>
                  <p className="data-content">{item.fullname}</p>
                </div>
              ) : null}

              {item.amount ? (
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Cərimə:")}</p>
                  <p className="data-content">{`${item.amount} AZN`}</p>
                </div>
              ) : null}

              {item.discount ? (
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Endirim:")}</p>
                  <p className="data-content">{`${item.discount} AZN`}</p>
                </div>
              ) : null}

              {item.total ? (
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Yekun məbləğ:")}</p>
                  <p className="data-content">{`${item.total} AZN`}</p>
                </div>
              ) : null}

              <div className="custom-row__data">
                <p className="data-title">{translate.get("Status:")}</p>
                <p className="data-content">{item.status.text}</p>
              </div>

              {item.speed_max ? (
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Sürət həddi:")}</p>
                  <p className="data-content">{`${item.speed_max} km/saat`}</p>
                </div>
              ) : null}

              {item.speed_real ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("Aşdığınız surət:")}
                  </p>
                  <p className="data-content">{`${item.speed_real} km/saat`}</p>
                </div>
              ) : null}

              {item.action_date ? (
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("Tarix:")}</p>
                  <p className="data-content">{item.action_date}</p>
                </div>
              ) : null}

              {item.decision_date ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("Qərar vurulma tarixi:")}
                  </p>
                  <p className="data-content">{item.decision_date}</p>
                </div>
              ) : null}

              {item.address ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("Qeydə alınma yeri:")}
                  </p>
                  <p className="data-content">{item.address}</p>
                </div>
              ) : null}

              {item.payment_date ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("Ödəniş tarixi:")}
                  </p>
                  <p className="data-content">{item.payment_date}</p>
                </div>
              ) : null}

              {item.law_item ? (
                <div className="custom-row__data">
                  <p className="data-title">{translate.get("İXM:")}</p>
                  <p className="data-content">{item.law_item}</p>
                </div>
              ) : null}
            </div>
          ) : null}
        </Fragment>
      ))}
    </>
  );
};
