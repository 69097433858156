import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { translate, globalFunctions, globalState } from "../../lib";
import { ScrollLoader, ComponentLoader } from "..";
import {
  ContentCars,
  ContentProtocols,
  ContentLicense,
  ContentHistory,
} from "../../layouts";
import $ from "jquery";

export class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchable: props.searchable,

      data: props.data,
      type: props.type,
      selected_ID: null,

      protocols: [],
      protocolText: "",

      license: null,
      licenseID: null,
      selectProps: props.car,

      screenWidth: window.innerWidth,

      isScrollLoading: props.isScrollLoading,
      isContentLoading: props.isContentLoading,
      isSearchLoading: props.isSearchLoading,
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });

    globalFunctions.set({
      setSidebarState: (data) => {
        this.setState(data);
      },
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.isScrollLoading !== state.isScrollLoading ||
      props.isContentLoading !== state.isContentLoading ||
      props.data !== state.data
    ) {
      return {
        data: props.data,
        isScrollLoading: props.isScrollLoading,
        isContentLoading: props.isContentLoading,
      };
    }
    return false;
  }

  componentDidMount() {
    this.setState({
      selected_ID: this.state.selectProps?.id
    })
  }

  render() {
    let {
      data,
      type,
      selected_ID,
      screenWidth,
      isScrollLoading,
      isContentLoading,
      isSearchLoading,
      protocols,
      protocolText,
      license,
      licenseID
    } = this.state;

    let rows = null;
    let footer = null;

    switch (type) {
      case "cars":
        if (screenWidth > 1024) {
          rows =  data.map((item, i) => (
              <div key={i}>
                <div
                  className={`sidebar__item ${
                      item.id === selected_ID ? "active" : ""
                  }`}
                  onClick={() => {
                    this.props.onGet(item);
                    this.setState({
                      selected_ID: item.id,
                    });
                  }}
                >
                  <div className="sidebar__item-content">
                    <div className="sidebar__item-title">
                      <p>{i+1}) {item.car_number}</p>
                    </div>
                    <div className="sidebar__item-date">
                      <div className="sidebar__item-date">
                        {/*<span>{`${translate.get("Bitmə tarixi:")} ${*/}
                        {/*    item.expire_date*/}
                        {/*}`}</span>*/}
                        {/*      <br />*/}
                        <span>{`${translate.get("")} ${
                          item.subscription_exp_date
                        }`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="sidebar__item-status">
                    {item.status !==  0 ?
                        (item.status === 1 ? <p className='active' >aktiv</p> :
                            <p className='no_balance' >balans yoxdur</p> ) :
                        <p className='deactive' >deaktiv</p>
                    }
                    <img src='/assets/images/chevron_right.svg' alt='' />
                    {/*{item.status !== 0 ? (*/}
                    {/*  <i*/}
                    {/*    className={`feather feather-check ${*/}
                    {/*      item.status === 1 ? "active" : "no_balance"*/}
                    {/*    }`}*/}
                    {/*  ></i>*/}
                    {/*) : (*/}
                    {/*  <i className="feather feather-x deactive"></i>*/}
                    {/*)}*/}
                  </div>
                </div>
              </div>
          ));
        } else {
          rows = data.map((item, i) => (
            <Fragment key={i}>
              <div
                className={`sidebar__item ${
                  item.id === selected_ID ? "active" : ""
                }`}
                onClick={() => {
                  this.props.onGet(item);
                  this.setState({
                    selected_ID: selected_ID !== item.id ? item.id : null,
                  });
                }}
              >
                <div className="sidebar__item-content">
                  <div className="sidebar__item-title">
                    <p>{i+1}) {item.car_number}</p>
                  </div>
                  <div className="sidebar__item-date">
                    <span>{`${translate.get("")} ${
                        item.subscription_exp_date
                    }`}</span>
                  </div>
                </div>
                <div className="sidebar__item-status">
                  <div className="sidebar__item-status">
                    {item.status !==  0 ?
                        (item.status === 1 ? <p className='active' >active</p> :
                            <p className='no_balance' >balans yoxdur</p> ) :
                        <p className='deactive' >deactive</p>
                    }
                    <img src='/assets/images/chevron_right.svg' alt='' />
                  </div>
                </div>
              </div>
              {item.id === selected_ID ? (
                <div className="sidebar__item-collapse">
                  <ContentCars object={item}
                               url={this.props.url}
                               openModal={this.props.openModal}
                               getInsuranceModal={this.props.getInsuranceModal}
                               IfNoInsurance={this.props.IfNoInsurance}
                               urlStatus={this.props.urlStatus}
                               activityModal={this.props.activityModal}
                               insuranceCompany={this.props.insuranceCompany}
                  />
                </div>
              ) : null}
            </Fragment>
          ));
        }

        footer = (
          <Link to="/cars/add" className="custom-btn w-100 export">
            {translate.get("Yeni Avtomobil")}
          </Link>
        );
        break;
      case "protocols":
        if (screenWidth > 1024) {
          rows = data.map((item, i) => (
            <div
              className={`sidebar__item ${
                item.id === selected_ID ? "active" : ""
              }`}
              key={i}
              onClick={() => {
                this.props.onGet(item);
                this.setState({
                  selected_ID: item.id,
                });
              }}
            >
              <div className="sidebar__item-content">
                <div className="sidebar__item-title">
                  <p>{i+1}) {item.car_number}</p>
                </div>
              </div>
              <div className="sidebar__item-status">
                {item.status !==  0 ?
                    (item.status === 1 ? <p className='active' >aktiv</p> :
                        <p className='no_balance' >balans yoxdur</p> ) :
                    <p className='deactive' >deaktiv</p>
                }
                <img src='/assets/images/chevron_right.svg' alt='' />

                {/*{item.status !== 0 ? (*/}
                {/*  <i*/}
                {/*    className={`feather feather-arrow-right ${*/}
                {/*      item.status === 1 ? "active" : "no_balance"*/}
                {/*    }`}*/}
                {/*  ></i>*/}
                {/*) : (*/}
                {/*  <i className="feather feather-arrow-down-right deactive"></i>*/}
                {/*)}*/}
              </div>
            </div>
          ));
        } else {
          rows = data.map((item, i) => (
            <Fragment key={i}>
              <div
                className={`sidebar__item ${
                  item.id === selected_ID ? "active" : ""
                }`}
                onClick={() => {
                  this.props.onGet(item);
                  this.setState({
                    selected_ID: selected_ID !== item.id ? item.id : null,
                  });
                }}
              >
                <div className="sidebar__item-content">
                  <div className="sidebar__item-title">
                    <p>{i+1}) {item.car_number}</p>
                  </div>
                </div>
                <div className="sidebar__item-status">
                  {item.status !==  0 ?
                      (item.status === 1 ? <p className='active' >active</p> :
                          <p className='no_balance' >balans yoxdur</p> ) :
                      <p className='deactive' >deactive</p>
                  }
                  <img src='/assets/images/chevron_right.svg' alt='' />
                  {/*{item.status !== 0 ? (*/}
                  {/*  <i*/}
                  {/*    className={`feather feather-check ${*/}
                  {/*      item.status === 1 ? "active" : "no_balance"*/}
                  {/*    }`}*/}
                  {/*  ></i>*/}
                  {/*) : (*/}
                  {/*  <i className="feather feather-x deactive"></i>*/}
                  {/*)}*/}
                </div>
              </div>
              {item.id === selected_ID ? (
                <div className="sidebar__item-collapse">
                  {isContentLoading ? (
                    <ComponentLoader />
                  ) : protocols.length ? (
                    <ContentProtocols
                      setID={this.props.setID}
                      onShow={this.props.onShow}
                      object={protocols}
                      printRef={this.props.printRef}
                      protocolID={this.props.protocolID}
                    />
                  ) : protocolText ? (
                    <div className="protocol__empty-content">
                      <img alt="" src="/assets/images/alert-big.svg" />
                      <p className="protocol__empty-content-text">
                        {protocolText}
                      </p>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </Fragment>
          ));
        }

        footer =
          data.length > 0 ? (
              <>
                <Link
                    target="blank"
                    to={{
                      pathname: `https://smsradar.az/apixxx/protocols/export?token=${globalState.get(
                          "token"
                      )}`
                    }}
                    download
                    className="custom-btn w-100 export"
                >
                  {translate.get("Bütün cərimələri export et")}
                </Link>

                {!this.props.isExportBtn ?
                    <button
                        type="button"
                        onClick={() =>
                            this.props.onExport(
                                `https://smsradar.az/apixxx/protocols/export?token=${globalState.get(
                                    "token"
                                )}`
                            )
                        }
                        style={{pointerEvents: this.props.isExport ? 'none' : '', opacity: this.props.isExport ? '.5' : ''}}
                        className="custom-btn w-100 export"
                    >
                      {translate.get("Son cərimələri export et (Yeni)")}
                    </button>
                    :
                    <Link
                        target="blank"
                        to={{
                          pathname: `https://smsradar.az/apixxx/protocols/export?token=${globalState.get(
                              "token"
                          )}`
                        }}
                        download
                        className="custom-btn w-100 export"
                    >
                      {translate.get("Cərimələri indi export et")}
                    </Link>
                }
                </>
          ) : (
            <Link to="/cars/add" className="custom-btn w-75">
              {translate.get("Avtomobil əlavə et")}
            </Link>
          );
        break;

      case "history":
        if (screenWidth > 1024) {
          rows = (
            <>
              {/* <div
                className="sidebar__item"
                style={{ cursor: "default", color: "#e06a7a", fontSize: 15 }}
              >
                Siz ödəniş tarixçədə son 1 il ərzində ödənilən cərimələri görə
                bilərsiz.
              </div> */}
              {data.map((item, i) => (
                <div
                  className={`sidebar__item ${
                    item.id === selected_ID ? "active" : ""
                  }`}
                  key={i}
                  onClick={() => {
                    this.props.onGet(item);
                    this.setState({
                      selected_ID: item.id,
                    });
                  }}
                >
                  <div className="sidebar__item-content">
                    <div className="sidebar__item-title">
                      <p>{i+1}) {item.car_number}</p>
                    </div>
                  </div>
                  <div className="sidebar__item-status">
                    {item.status !==  0 ?
                        (item.status === 1 ? <p className='active' >aktiv</p> :
                            <p className='no_balance' >balans yoxdur</p> ) :
                        <p className='deactive' >deaktiv</p>
                    }
                    <img src='/assets/images/chevron_right.svg' alt='' />
                    {/*{item.status !== 0 ? (*/}
                    {/*  <i*/}
                    {/*    className={`feather feather-check ${*/}
                    {/*      item.status === 1 ? "active" : "no_balance"*/}
                    {/*    }`}*/}
                    {/*  ></i>*/}
                    {/*) : (*/}
                    {/*  <i className="feather feather-x deactive"></i>*/}
                    {/*)}*/}
                  </div>
                </div>
              ))}
            </>
          );
        } else {
          rows = (
            <>
              {/* <div
                className="sidebar__item"
                style={{ cursor: "default", color: "#e06a7a", fontSize: 15 }}
              >
                Siz ödəniş tarixçədə son 1 il ərzində ödənilən cərimələri görə
                bilərsiz.
              </div> */}
              {data.map((item, i) => (
                <Fragment key={i}>
                  <div
                    className={`sidebar__item ${
                      item.id === selected_ID ? "active" : ""
                    }`}
                    onClick={() => {
                      this.props.onGet(item);
                      this.setState({
                        selected_ID: selected_ID !== item.id ? item.id : null,
                      });
                    }}
                  >
                    <div className="sidebar__item-content">
                      <div className="sidebar__item-title">
                        <p>{i+1}) {item.car_number}</p>
                      </div>
                    </div>
                    <div className="sidebar__item-status">
                      {item.status !==  0 ?
                          (item.status === 1 ? <p className='active' >aktiv</p> :
                              <p className='no_balance' >balans yoxdur</p> ) :
                          <p className='deactive' >deaktiv</p>
                      }
                      <img src='/assets/images/chevron_right.svg' alt='' />
                      {/*{item.status !== 0 ? (*/}
                      {/*  <i*/}
                      {/*    className={`feather feather-check ${*/}
                      {/*      item.status === 1 ? "active" : "no_balance"*/}
                      {/*    }`}*/}
                      {/*  ></i>*/}
                      {/*) : (*/}
                      {/*  <i className="feather feather-x deactive"></i>*/}
                      {/*)}*/}
                    </div>
                  </div>
                  {item.id === selected_ID ? (
                    <div className="sidebar__item-collapse">
                      {isContentLoading ? (
                        <ComponentLoader />
                      ) : protocols.length ? (
                        <ContentHistory
                          setID={this.props.setID}
                          onShow={this.props.onShow}
                          object={protocols}
                        />
                      ) : protocolText ? (
                        <div className="protocol__empty-content">
                          <img alt="" src="/assets/images/alert-big.svg" />
                          <p className="protocol__empty-content-text">
                            {protocolText}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </Fragment>
              ))}
            </>
          );
        }
        break;
      case "license":
        if (screenWidth > 1024) {
          rows = data.map((item, i) => (
            <div
              className={`sidebar__item  ${
                item.id === selected_ID ? "active" : ""
              }`}
              style={{
                height: 'auto',
              }}
              key={i}
              title={item.statusText ? item.statusText : ""}
              onClick={() => {
                this.props.onGet(item);
                this.setState({
                  selected_ID: item.id,
                });
              }}
            >
              {/*<div className="sidebar__item-image">*/}
              {/*  <img alt="" src="/assets/images/passport_min.svg" />*/}
              {/*</div>*/}
              <div className={`sidebar__item-content`}>
                <div className={`sidebar__item-title`} >
                  <p>{i+1}) {`${item.seria + item.number}`}</p>
                </div>
                <div className="sidebar__item-date">
                  <span>{`${translate.get("Vəsiqənin bitmə tarixi:")} ${
                      item.expire_date
                  }`}</span>
                  <br />
                  {
                    item.status === 1 ?
                    <span>{`${translate.get("Abunəliyin bitmə tarixi:")} ${
                      item.subscription_exp_date
                    }`}</span> : null
                  }
                </div>
              </div>
              <div className="d-flex flex-column align-items-center">
                <div className="sidebar__item-status mb-2">
                </div>
                <div
                  className="sidebar__item-status"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.onDelete(item);
                  }}
                >
                  <i className="feather feather-trash-2 delete" style={{ marginTop:-10 }} />
                </div>
              </div>
            </div>
          ));
        } else {
          rows = data.map((item, i) => (
            <Fragment key={i}>
              <div
                className={`sidebar__item ${
                  item.id === selected_ID ? "active" : ""
                }`}
                onClick={() => {
                  this.props.onGet(item);
                  this.setState({
                    selected_ID: selected_ID !== item.id ? item.id : null,
                  });
                }}
              >
                <div className="sidebar__item-content">
                  <div className="sidebar__item-title">
                    <p>{i+1}) {`${item.seria + item.number}`}</p>
                  </div>
                  <div className="sidebar__item-date">
                  <span>{`${translate.get("Vəsiqənin bitmə tarixi:")} ${
                      item.expire_date
                  }`}</span>
                    <br />
                    {item.status === 1 ?
                    <span>{`${translate.get("Abunəliyin bitmə tarixi:")} ${
                        item.subscription_exp_date
                    }`}</span> : null
                    }
                  </div>
                </div>
                <div
                  className="sidebar__item-status"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.onDelete(item);
                  }}
                >
                  <i className="feather feather-trash-2 delete"></i>
                </div>
              </div>
              {item.id === selected_ID ? (
                <div className="sidebar__item-collapse">
                  {isContentLoading ? (
                    <ComponentLoader />
                  ) : (
                    license && (
                      <ContentLicense
                        canPay={true}
                        canDelete={true}
                        object={license}
                        objectID={licenseID}
                      />
                    )
                  )}
                </div>
              ) : null}
            </Fragment>
          ));
        }

        footer = (
          <Link to="/licenses/add" className="custom-btn w-100 export">
            {translate.get("Vəsiqə əlavə et")}
          </Link>
        );
        break;
      default:
        break;
    }

    return (
        <>
          {data.length ? <div
              className="Sidebar"
              onScroll={this.props.onScroll}
          >
            {/*{searchable && (*/}
            {/*  <div className="sidebar__item-header">*/}
            {/*    <input*/}
            {/*      type="text"*/}
            {/*      maxLength="7"*/}
            {/*      placeholder="Axtarın (min. 5 simvol)"*/}
            {/*      className="custom-input min"*/}
            {/*      onChange={this.props.onSearch}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
            <div
                className="sidebar__item-container"
                // style={{
                //   height:
                //       screenWidth > 1024
                //           ? searchable && data.length > 0
                //           ? "75%"
                //           : "85%"
                //           : "auto",
                // }}
            >

              {isSearchLoading ? <ComponentLoader/> : rows}
            </div>

            {isScrollLoading ? (
                <ScrollLoader/>
            ) : (
                <div className="sidebar__item-footer">{footer}</div>
            )}
          </div> : null}
          </>
    );
  }
}
