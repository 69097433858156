import React from 'react';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export const Million = React.memo(({modal, onClose}) => {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={modal}
      onHide={onClose}
    >
      <Modal.Header>
        <div
          className="d-flex justify-content-between align-items-center w-100">
          <h5 className="mb-0">Million ödəniş terminalı vasitəsi ilə ödənişin
            izahı</h5>
          <button
            className="close-btn feather feather-x"
            onClick={onClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="balance-modal row">

          {/* --- */}
          <div className="balance-modal--item">
            <div style={{
              color: '#6d6d6d',
              fontSize: 18,
              marginBottom: '1rem',
              textAlign: 'center',
            }}>
              <b>1. </b>
              <span
                style={{fontWeight: 300}}>Ekranda ödəmə kartlarını seçin</span>
            </div>
            <a href="https://smsradar.az/page/screens/4.jpeg" target="_blank">
              <img src="/assets/million-images/1.jpeg" alt=""/>
            </a>
          </div>

          <div className="balance-modal--item">
            <div style={{
              color: '#6d6d6d',
              fontSize: 18,
              marginBottom: '1rem',
              textAlign: 'center',
            }}>
              <b>2. </b>
              <span style={{fontWeight: 300}}>SMSRadar seçin</span>
            </div>
            <a href="https://smsradar.az/page/screens/5.jpeg" target="_blank">
              <img src="/assets/million-images/2.jpeg" alt=""/>
            </a>
          </div>

          <div className="balance-modal--item">
            <div style={{
              color: '#6d6d6d',
              fontSize: 18,
              marginBottom: '1rem',
              textAlign: 'center',
            }}>
              <b>3. </b>
              <span style={{fontWeight: 300}}>
                994 - den sonra istifadə etdiyiniz mobil operatorun kodunu yazın. Məsələn 50, ardınca 7 rəqəmli mobil nömrənizi daxil edin. Məsələn: 4086061
              </span>
            </div>
            <a href="https://smsradar.az/page/screens/1.jpeg" target="_blank">
              <img src="/assets/million-images/3.jpeg" alt=""/>
            </a>
          </div>

          <div className="balance-modal--item">
            <div style={{
              color: '#6d6d6d',
              fontSize: 18,
              marginBottom: '1rem',
              textAlign: 'center',
            }}>
              <b>4. </b>
              <span style={{fontWeight: 300}}>Mobil nömrənizin düzgün olmasını təsdiqləyin.</span>
            </div>
            <a href="https://smsradar.az/page/screens/2.jpeg" target="_blank">
              <img src="/assets/million-images/4.jpeg" alt=""/>
            </a>
          </div>

          <div className="balance-modal--item">
            <div style={{
              color: '#6d6d6d',
              fontSize: 18,
              marginBottom: '1rem',
              textAlign: 'center',
            }}>
              <b>5. </b>
              <span style={{fontWeight: 300}}>Nəğd pulla ödənişi seçin.</span>
            </div>
            <a href="https://smsradar.az/page/screens/3.jpeg" target="_blank">
              <img src="/assets/million-images/5.jpeg" alt=""/>
            </a>
          </div>

          <div className="balance-modal--item">
            <div style={{
              color: '#6d6d6d',
              fontSize: 18,
              marginBottom: '1rem',
              textAlign: 'center',
            }}>
              <b>6. </b>
              <span style={{fontWeight: 300}}>Vəsaiti daxil edib təsdiqlədikdən sonra məbləğ SMSRadardakı balansınıza yüklənəcək</span>
            </div>
            <a href="https://smsradar.az/page/screens/3.jpeg" target="_blank">
              <img src="/assets/million-images/6.jpeg" alt=""/>
            </a>
          </div>
          {/* --- */}

          <div className="w-100 px-3 mt-3">
            <button className="custom-btn btn-block" onClick={onClose}>
              Bağla
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});