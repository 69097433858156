import React from "react";
import { translate } from "../../lib";

export const Modal = ({onHide}) => {

        return (
            <div className="custom__modal-container" onClick={(e)=> onHide + e.stopPropagation() }>
                <div className="custom__modal">
                    <div className="custom__modal-header" >
                        <div className="modal__title">
                            {translate.get("Qaydalar")}
                        </div>
                        <button
                            onClick={onHide}
                            className="close-btn feather feather-x"
                        ></button>
                    </div>
                    <div className="custom__modal-body">
                        <div className="modal_content">
                            <div className="modal__info-content">
                                <h4 className="text-center mb-4">
                                    Qaydalar
                                </h4>
                                <ul>
                                    <li>
                                        <b>1.</b>Siz SMSRADAR xidmetine qoşulmaq üçün qeydiyyatdan
                                        keçdiyiniz zaman balansınızdan 1 AZN ( EDV daxil) mebleğ çıxılacaq.
                                    </li>
                                    <li className='mt-4' >
                                        <b>2.</b>Abunelik müddeti 30 gün intervalla hesablanır. Xidmetden imtina etmediyiniz
                                        müddetde abuneliyiniz avtomatik olaraq her ay yenilenecek ve balansınızdan 1 AZN (EDV daxil) mebleğ çıxılacaq.
                                    </li>
                                    <li className='mt-4'  >
                                        <b>3.</b>Her hansı bir şexsin razılığı olmadan ona mexsus avtomobilin melumatları ile
                                        qeydiyyatdan keçmek qadağandır! Bu kimi hallar aşkarlanarsa qanunvericiliye uyğun olaraq mesuliyyet daşıyırsınız.
                                    </li>
                                    <li className='mt-4'  >
                                        <b>4.</b>Siz razıyam seçimini etdikden sonra yuxarıda gösterilen qaydaları qebul etmiş sayılırsınız.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}
