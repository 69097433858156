import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../lib";

export class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null
    };
  }

  componentDidMount() {
    let date = new Date();
    this.setState({
      date: date.getFullYear()
    });
  }

  render() {
    let { date } = this.state;

    return (
        <footer>
          <div className="footer__top-section">
            <div className="custom-container">
              <div className="row">
                <div className="col-md-5 d-flex my-2 my-md-0 justify-content-center justify-content-md-between align-items-center">
                  <div className="footer__card-icons">
                    <Link to="">
                      <img
                          src="/assets/images/mobilex.svg"
                          alt="Mobilex Payment"
                          title="Mobilex Payment"
                      />
                    </Link>
                    <Link
                        target="blank"
                        to={{
                          pathname: "https://www.azercell.com/az/"
                        }}
                    >
                      <img
                          src="/assets/images/azercell.svg"
                          alt="Azercell"
                          title="Azercell"
                      />
                    </Link>
                    <Link
                        target="blank"
                        className='bakcell'
                        to={{
                          pathname: "https://www.bakcell.com/az"
                        }}
                    >
                      <img
                          src="/assets/images/bakcell.svg"
                          alt="Bakcell"
                          title="Bakcell"
                      />
                    </Link>
                    <Link
                        target="blank"
                        to={{
                          pathname: "https://www.nar.az/"
                        }}
                    >
                      <img
                          src="/assets/images/nar.svg"
                          alt="Nar mobile"
                          title="Nar mobile"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-md-7 d-flex my-2 my-md-0 justify-content-md-end justify-content-center align-items-center">
                  <div className="footer__store-icons">
                      <Link
                          target="blank"
                          to={{
                              pathname: "https://appgallery.huawei.com/#/app/C104284951"
                          }}
                      >
                          <img
                              className='app_glory'
                              src="/assets/images/AppGallery.png"
                              alt="App Gallery"
                              title="App Gallery"
                          />
                      </Link>
                    <Link
                        target="blank"
                        to={{
                          pathname:
                              "https://apps.apple.com/us/app/smsradar-az-dyp-bna-c%C9%99rim%C9%99l%C9%99ri/id1112731295"
                        }}
                    >
                      <img
                          src="/assets/images/app-store.svg"
                          alt="Apple Store"
                          title="Download from App Store"
                      />
                    </Link>
                    <Link
                        target="blank"
                        to={{
                          pathname:
                              "https://play.google.com/store/apps/details?id=com.smsradar.mobilex"
                        }}
                    >
                      <img
                          src="/assets/images/play-store.svg"
                          alt="Play Store"
                          title="Download from Play Store"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom-section">
            <div className="custom-container">
              <div className="row">
                <div className="col-md-6 d-flex my-2 my-md-0 align-items-center justify-content-center justify-content-md-start">
                  <p>{`© ${date} SMSRadar.az ${translate.get(
                      "Bütün hüquqlar qorunur"
                  )}`}</p>
                </div>
                <div className="col-md-6 d-flex my-2 my-md-0 align-items-center justify-content-center justify-content-md-end">
                  <Link
                      target="blank"
                      to={{
                        pathname: "tel:+9940124086061"
                      }}
                  >
                    <i className="feather feather-phone-call"></i>012 408 60 61
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </footer>
    );
  }
}
