import React, {Fragment} from 'react';
import {translate} from '../../lib';
import {protocolList} from '../../actions';
import {Loader} from '../../components';

export const PrintContentAll = ({match: {params: {id}}}) => {

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);

  const loadData = async () => {
    setLoading(true);
    let response = await protocolList({car_number: id});
    if (response) {
      if (response.status === 'success') {
        setData(response.data);
        setTimeout(() => {
          setLoading(false);
          var divElements = document.getElementById('printContent').innerHTML;
          document.body.className = 'print-body';
          document.body.innerHTML =
            '<html><head><title></title></head><body>' +
            divElements + '</body>';
          window.print();
        }, 300);
      }
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <React.Fragment>
      {loading && <Loader/>}
      <div id="printContent">
        {data.map((item, i) => (
          <Fragment key={i}>
            {item.protocol_number ||
            item.fullname ||
            item.amount ||
            item.discount ||
            item.car_number ||
            item.total ||
            item.has_decision ||
            item.speed_max ||
            item.speed_real ||
            item.action_date ||
            item.decision_date ||
            item.address ||
            item.law_item ? (
              <React.Fragment>
                <div className="custom-row pt-4" style={{borderBottom: 'none'}}>
                  {item.car_number ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get('Nömrə:')}</p>
                      <p className="data-content">
                        {item.car_number}
                      </p>
                    </div>
                  ) : null}
                  {item.protocol_number ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get('Protokol:')}</p>
                      <p className="data-content">
                        {item.protocol_series + item.protocol_number}
                      </p>
                    </div>
                  ) : null}

                  {item.fullname.trim().length ? (
                    <div className="custom-row__data">
                      <p className="data-title">
                        {translate.get('Cərimə olunan:')}
                      </p>
                      <p className="data-content">{item.fullname}</p>
                    </div>
                  ) : null}

                  {item.amount ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get('Cərimə:')}</p>
                      <p className="data-content">{`${item.amount} AZN`}</p>
                    </div>
                  ) : null}

                  {item.discount ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get('Endirim:')}</p>
                      <p className="data-content">{`${item.discount} AZN`}</p>
                    </div>
                  ) : null}

                  {item.total ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get(
                        'Yekun məbləğ:')}</p>
                      <p className="data-content">{`${item.total} AZN`}</p>
                    </div>
                  ) : null}

                  <div className="custom-row__data">
                    <p className="data-title">{translate.get('Status:')}</p>
                    <p className="data-content">{item.status.text}</p>
                  </div>

                  {item.speed_max ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get(
                        'Sürət həddi:')}</p>
                      <p
                        className="data-content">{`${item.speed_max} km/saat`}</p>
                    </div>
                  ) : null}

                  {item.speed_real ? (
                    <div className="custom-row__data">
                      <p className="data-title">
                        {translate.get('Aşdığınız surət:')}
                      </p>
                      <p
                        className="data-content">{`${item.speed_real} km/saat`}</p>
                    </div>
                  ) : null}

                  {item.action_date ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get('Tarix:')}</p>
                      <p className="data-content">{item.action_date}</p>
                    </div>
                  ) : null}

                  {item.decision_date ? (
                    <div className="custom-row__data">
                      <p className="data-title">
                        {translate.get('Qərar vurulma tarixi:')}
                      </p>
                      <p className="data-content">{item.decision_date}</p>
                    </div>
                  ) : null}

                  {item.address ? (
                    <div className="custom-row__data">
                      <p className="data-title">
                        {translate.get('Qeydə alınma yeri:')}
                      </p>
                      <p className="data-content">{item.address}</p>
                    </div>
                  ) : null}

                  {item.law_item ? (
                    <div className="custom-row__data">
                      <p className="data-title">{translate.get('İXM:')}</p>
                      <p className="data-content">{item.law_item}</p>
                    </div>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};