import cookie from "react-cookies";
import { globalState, functions } from ".";
import { config } from "../config";
import { toast } from "./toast";
import {accountLogout} from '../actions';

function filterParams(params) {
  let obj = {};
  Object.keys(params).map(key => {
    let value = params[key];
    if (!value) {
      value = "";
    }
    obj[key] = value;

    return obj;
  });
  return obj;
}

export async function get(url, params = {}, ignore = false) {
  let _lang = cookie.load("_lang");

  if (typeof _lang !== "undefined") {
    params.lang = _lang;
  }

  let response = await fetch(
    `${globalState.get("apiRoutes")[url]}?${functions.serializeQuery(
      filterParams(params)
    )}`, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer"
    }
  )
  .then(async response => response && await response.json())
  .catch(err => {
    toast.fire({
      icon: "error",
      title: "Serverlə əlaqə xətası.",
      timer: 10000
    });
  });

  if (response && response.error_code === 1001) {
    throw Error(response.description)
  }
  return response;
}

export const post = async (url, params = {}, ignore = false) => {
  let _lang = cookie.load("_lang");

  if (typeof _lang !== "undefined") {
    params.lang = _lang;
  }

  return await fetch(
    globalState.get("apiRoutes")[url] + "?_env=" + config.env,
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: params,
    }
  ).then(async response => await response.json());
};

export const put = async (url, params = {}, ignore = false) => {
  let _lang = cookie.load("_lang");

  if (_lang) {
    params.append("lang", _lang);
  }

  params.append("_env", config.env);

  return await fetch(`${globalState.get("apiRoutes")[url]}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "User-Agent": "Mozilla/5.0"
    },
    credentials: "include",
    mode: "cors",
    cache: "no-cache",
    method: "POST",
    body: params
  }).then(async response => await response.json());
};