import { api } from "../lib";

export async function protocolList(params) {
  return await api.get("protocolList", params);
}

export async function protocolPay(params) {
  return await api.get("protocolPay", params);
}

export async function protocolFiles(params) {
  return await api.get("protocolFiles", params);
}

export async function protocolExport(params) {
  return await api.get("protocolExport", params);
}
