import React from "react";
import { ComponentLoader, ComponentHeader } from "../../components";
import { translate, toast } from "../../lib";
// import { protocolPay } from "../../actions";

export class ProtocolPay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      object: props.location.object
        ? JSON.parse(props.location.object.data)
        : null,
      protocol_series: "",
      protocol_serie: "",
      protocol_id: "",
      show_iframe: false,
      loading_iframe: true,
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    let { protocol_id, protocol_serie } = this.state;

    if (protocol_id.trim() && protocol_serie.trim()) {
      window.open(`https://api.az/${protocol_serie + protocol_id}`);
    } else {
      this.setState({
        isLoading: false,
        loading_iframe: false,
        show_iframe: false,
      });
      toast.fire({
        icon: "error",
        title: "Məlumatları düzgün daxil edin",
      });
    }
    // let params = { protocol_id: protocol_serie + protocol_id };
    // let response = await protocolPay(params);

    // if (response) {
    //   this.setState({ isLoading: false });
    //   toast.fire({
    //     icon: response.status === "success" ? "success" : "error",
    //     title: response.description
    //   });
    //   if (response.status === "success") {
    //     this.setState({ protocol_id: "", protocol_series: "" });
    //   }
    // }
  };

  componentDidMount() {
    if (this.state.object) {
      let { protocol_series, protocol_number } = this.state.object;
      this.setState({
        protocol_series,
        protocol_serie: protocol_series,
        protocol_id: protocol_number,
      });
    }
    window.scrollTo(0, 0);
  }

  onLoad = () => {
    this.setState({ loading_iframe: false });
  };

  render() {
    let {
      isLoading,
      protocol_id,
      protocol_serie,
      protocol_series,
      show_iframe,
      loading_iframe,
    } = this.state;

    return (
      <div className="content" style={{ background: "#fff" }}>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="Pay">
            <ComponentHeader
              title={translate.get("Cərimə ödə")}
              back={"/"}
              msg={true}
              grid={true}
            />
            <div className="protocol-pay__content">
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  {!protocol_series ? (
                    <div className="col-12 mb-4">
                      <div className="protocol-pay__serie-container ">
                        <div
                          className="protocol__serie-item col-md-6 col-12"
                          onClick={() => {
                            this.setState({
                              protocol_series: "BNA",
                              protocol_serie: "BNA",
                            });
                          }}
                        >
                          <img alt="" src="/assets/images/bna.svg" />
                          <p className="protocol__serie-item-title">
                            {translate.get("Bakı nəqliyyat agentliyi")}
                          </p>
                        </div>
                        <div
                          className="protocol__serie-item b_img col-md-6 col-12"
                          onClick={() => {
                            this.setState({
                              protocol_series: "DIN",
                              protocol_serie: "",
                            });
                          }}
                        >
                          <img alt="" src="/assets/images/din.svg" />
                          <p className="protocol__serie-item-title">
                            {translate.get("Daxili işlər nazirliyi")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="col-md-6 mb-4">
                        <button
                          className="custom-btn"
                          onClick={() => {
                            this.setState({
                              protocol_series: "",
                              protocol_serie: "",
                              protocol_id: "",
                            });
                          }}
                        >
                          <i className="feather feather-arrow-left text-white"></i>
                        </button>
                      </div>

                      <div className="col-md-12 mb-4">
                        <div className="protocol-pay__serie-container bb">
                          {protocol_series === "BNA" ? (
                            <div className="protocol__serie-item">
                              <img alt="" src="/assets/images/bna.svg" />
                            </div>
                          ) : (
                            <div className="protocol__serie-item">
                              <img alt="" src="/assets/images/din.svg" />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12 mb-4">
                        <label className="custom-different-label">
                          {translate.get("Protokolun seriyası")}
                        </label>
                        <input
                          required
                          readOnly={protocol_serie === "BNA"}
                          type="text"
                          className="custom-different-input"
                          placeholder={translate.get("AA")}
                          value={protocol_serie}
                          onChange={(e) => {
                            this.setState({ protocol_serie: e.target.value });
                          }}
                        />
                      </div>

                      <div className="col-md-12 mb-4">
                        <label className="custom-different-label">
                          {translate.get("Protokolun nömrəsi")}
                        </label>
                        <input
                          required
                          type="text"
                          className="custom-different-input"
                          placeholder={translate.get("0000000")}
                          value={protocol_id}
                          onChange={(e) => {
                            this.setState({ protocol_id: e.target.value });
                          }}
                        />
                      </div>

                      <div className="col-md-12">
                        <button type="submit" className="custom-btn  w-100">
                          {translate.get("Təsdiqlə")}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </form>
              {/*{show_iframe ? (*/}
              {/*  <React.Fragment>*/}
              {/*    {loading_iframe ? <ComponentLoader /> : null}*/}
              {/*    {!loading_iframe && (*/}
              {/*      <button*/}
              {/*        className="btn shadow-none"*/}
              {/*        onClick={() => {*/}
              {/*          this.setState({ show_iframe: false });*/}
              {/*          setTimeout(() => {*/}
              {/*            this.setState({ loading_iframe: true });*/}
              {/*          }, 300);*/}
              {/*        }}*/}
              {/*        style={{*/}
              {/*          position: "absolute",*/}
              {/*          right: 10,*/}
              {/*          top: 77,*/}
              {/*          fontSize: 20,*/}
              {/*          color: "black",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <i className="feather feather-x" />*/}
              {/*      </button>*/}
              {/*    )}*/}
              {/*    <iframe*/}
              {/*      src={`https://api.az/${protocol_serie + protocol_id}`}*/}
              {/*      width="100%"*/}
              {/*      height="520"*/}
              {/*      allowFullScreen*/}
              {/*      onLoad={this.onLoad}*/}
              {/*      style={{ border: "none", borderRadius: 7 }}*/}
              {/*    />*/}
              {/*  </React.Fragment>*/}
              {/*) : (*/}
              {/* */}
              {/*)}*/}
            </div>
          </div>
        )}
      </div>
    );
  }
}
