import { api } from "../lib";

export async function accountSignIn(params) {
  return await api.get("accountSignIn", params);
}

export async function accountForgotPassword(params) {
  return await api.get("accountForgotPassword", params);
}

export async function accountLogout(params) {
  return await api.get("accountLogout", params);
}

export async function accountSignUp_FS(params) {
  return await api.get("accountSignUp_FS", params);
}

export async function accountSignUp_LS(params) {
  return await api.get("accountSignUp_LS", params);
}

export async function carsAdd(params) {
  return await api.get("carsAdd", params);
}

export async function authCheck(params) {
  return await api.get("authCheck", params);
}

export async function accountRegister_FS(params) {
  return await api.get("accountRegister_FS", params);
}

export async function accountRegister_LS(params) {
  return await api.get("accountRegister_LS", params);
}

export async function accountInfo(params) {
  return await api.get("accountInfo", params);
}

export async function accountSettings(params) {
  return await api.get("accountSettings", params);
}

export async function accountChangePassword(params) {
  return await api.get("accountChangePassword", params);
}

export async function accountChangeNumber_FS(params) {
  return await api.get("accountChangeNumber_FS", params);
}

export async function accountChangeNumber_LS(params) {
  return await api.get("accountChangeNumber_LS", params);
}
