import React from 'react';
import {Modal} from 'react-bootstrap';
import {ComponentLoader} from '../../components';

export const Activity = ({
       activity,
       onClose,
       activityLoading,
       car,
     }) => {

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={activity}
      onHide={onClose}
    >
      <Modal.Header>
        <div
          className="d-flex justify-content-between align-items-center w-100">
          <h5 className="mb-0">{car?.status == 1 ?
            'Deaktiv et' :
            'Aktiv edildi'}</h5>
          <button
            className="close-btn feather feather-x"
            onClick={onClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        {activityLoading ?
          <div className="p-5">
            <ComponentLoader/>
          </div>
          :
          <>
            <p style={{color: '#777', fontSize: '18px'}}
               className="text-center"
            >
              SMSRadar xidmətini deaktiv etmək
              ücün <b>Stop{car?.car_number}</b> yazıb SMS-lə <b>5666</b>-a
              göndərin. <b>Ətraflı: 1114</b>
            </p>
            <div className="w-100 px-2   mt-3">
              <button className="custom-btn btn-block" onClick={onClose}>
                Bağla
              </button>
            </div>
          </>
        }
      </Modal.Body>
    </Modal>
  );
};