import React from "react";
import { Link } from "react-router-dom";
import {
  user,
  translate,
  globalState,
  globalFunctions,
  toast
} from "../../lib";
import { messageList, messageDelete } from "../../actions";
import { ComponentLoader } from "../../components";
import Swal from "sweetalert2";
import $ from "jquery";

export class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenWidth: window.innerWidth,
      isDropdownShow: false,
      isLoading: false,

      messages: [],
      count: 0,

      new_messages_count: user.isAuth() ? user.getData().new_messages_count : 0,
      number: "",
      operator: ""
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });

    $(document).on("click", e => {
      if (
        !$(".custom__dropdown").is(e.target) &&
        !$(".message_toogler").is(e.target) &&
        $(".custom__dropdown").has(e.target).length === 0 &&
        $(".message_toogler").has(e.target).length === 0
      ) {
        this.setState({ isDropdownShow: false });
      }
    });

    globalFunctions.set({
      reLoadMessages: () => {
        this.reLoad();
      }
    });
  }

  onDelete = async id => {
    Swal.fire({
      title: translate.get("Doğrulayın"),
      text: translate.get("Mesajı silmək istədiyinizə əminsinizmi?"),
      icon: null,
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonClass: "custom-btn success-btn",
      cancelButtonClass: "custom-btn default-btn",
      confirmButtonText: translate.get("Bəli"),
      cancelButtonText: translate.get("Xeyr")
    }).then(async res => {
      if (res && res.value) {
        this.setState({
          isLoading: true
        });
        let response = await messageDelete({ id });
        if (response) {
          this.setState({
            isLoading: false
          });
          toast.fire({
            icon: response.status === "success" ? "success" : "error",
            title: response.description
          });
          if (response.status === "success") {
            this.reLoad();
            globalFunctions._reLoadMessages();
          }
        }
      }
    });
  };

  reLoad = async () => {
    this.setState({ isLoading: true });
    let response = await messageList();
    if (response) {
      this.setState({ isLoading: false });
      if (response.status === "success") {
        this.setState({
          messages: response.data,
          count: response.count
        });
      } else {
        this.setState({
          messages: [],
          count: 0
        });
      }
    }
  };

  render() {
    let {
      screenWidth,
      isLoading,
      isDropdownShow,
      messages,
      count,
      new_messages_count
    } = this.state;

    return (
      <header>
        {isDropdownShow && <div className='backdrop'/>}

        <div className="custom-container">
          <div className="content">

            <div className="header__logo">
              {screenWidth > 1024 ?
                <Link to="/">
                  <img src="/assets/images/logomob.svg" alt=""/>
                </Link> :
                <>
                  {/*<Link to="/">*/}
                  {/*  <img src="/assets/images/logomob.svg" alt=""/>*/}
                  {/*</Link>*/}
                  <a  href={"tel:1114"}  className='contact_number custom-link' >
                    <img src='/assets/images/phone_white.svg' className='mx-2' alt="" />
                    <span>1114</span>
                  </a>
                </>
              }
            </div>

            <div className="header__actions d-flex">
              {!user.isAuth() &&
                <div className='header__auth'>
                  <a href={"tel:1114"}  className='contact_number custom-link' >
                    <img src='/assets/images/phone_white.svg' className='mx-2' alt="" />
                    <span>1114</span>
                  </a>
                  <Link to={'/register'} >
                    <button type={'button'} className='signin_btn mx-3' >{translate.get("Daxil ol / Qeydiyyat")}</button>
                  </Link>
                </div>
              }

              {user.isAuth() && (
                <div className="header__auth-info">
                  <a href={"tel:1114"} className='contact_number custom-link' >
                    <img src='/assets/images/phone_green.svg' className='mx-2' alt="" />
                    <span>1114</span>
                  </a>
                  <div className="header__auth-operator"  >
                    <div className="auth-operator-toogler">
                      <span className="operator-number">{`0${user
                          .getData()
                          .msisdn.slice(3)}`}</span>
                      <span className="operator-name">
                        {
                          globalState
                              .get("operators")
                              .find(opr => opr.id === user.getData().operator)
                              .name
                        }
                      </span>
                    </div>
                  </div>
                  <Link to="/" className="header__menu-toogler">
                    <img src="/assets/images/grid.svg" alt="" />
                    {/*<span>{translate.get("Menyu")}</span>*/}
                  </Link>
                  <div className="header__auth-messages">
                    {screenWidth < 1024 ? (
                      <Link
                        to="/messages"
                        className="message_toogler"
                        onClick={() => {
                          this.setState({ new_messages_count: 0 });
                        }}
                      >
                        <img src='/assets/images/messenger.svg' alt='' />
                        {new_messages_count ? (
                          <span className="message-count">
                            {new_messages_count}
                          </span>
                        ) : null}
                      </Link>
                    ) : (
                      <button
                        className="message_toogler"
                        onClick={async () => {
                          await this.setState({
                            isDropdownShow: !this.state.isDropdownShow,
                            new_messages_count: 0
                          });
                          if (this.state.isDropdownShow) {
                            this.reLoad();
                          }
                        }}
                      >
                        <img src='/assets/images/messenger.svg' alt='' />
                        {new_messages_count ? (
                          <span className="message-count">
                            {new_messages_count}
                          </span>
                        ) : null}
                      </button>
                    )}
                  </div>
                  <div className='log-out-side' >
                    <i className='feather feather-log-out' onClick={()=> user.logOut()} />
                  </div>
                  {/* <div className="header__auth-logout">
                    <button
                      onClick={() => {
                        user.logOut();
                      }}
                    >
                      <img alt="Log out" src="/assets/images/logout.svg" />
                    </button>
                  </div> */}
                </div>
              )
              // :
              // (
              //   <div className="header__auth-buttons">
              //     <Link
              //       to="/register"
              //       className="custom-btn min light-btn mr-2"
              //     >
              //       Qeydiyyat
              //     </Link>
              //     <Link to="/login" className="custom-btn min success-btn">
              //       <i className="feather feather-user"></i>Daxil ol
              //     </Link>
              //   </div>
              // )
              }
            </div>
          </div>
        </div>




        {user.isAuth() && isDropdownShow && screenWidth >= 1024 ? (
          <div className="custom__dropdown" style={{ marginRight:'-.5rem' }} >
            <div className="custom__dropdown-header">
              <img src="/assets/images/messenger.svg" alt=""/>
              <p>
                {translate.get("Mesajlar")}
              </p>
            </div>
            <div className="custom__dropdown-content">
              <div
                className={`custom__dropdown-item-container ${
                  messages.length ? "" : "d-flex"
                }`}
              >
                {isLoading ? (
                  <ComponentLoader />
                ) : messages.length ? (
                  messages.map((data, i) => (
                    <div className="message__item" key={i}>
                      <div className="message__item-content row">
                        <div className='col-4 p-0 m-0'>
                          <span>{`${i + 1}`}</span>
                          <p className='mt-1' >
                            {data.created_at.slice(11,16)}
                            <p>{data.created_at.slice(0,10)}</p>
                          </p>
                        </div>

                        <div className='col-8 p-0 m-0' >
                          <p  className='mes' >{data.message}</p>
                        </div>
                      </div>
                      {/*<div className="message__item-footer">*/}
                      {/*  /!* <div className="message__item-image">*/}
                      {/*        <img src="/assets/images/aze_flag.svg" alt="" />*/}
                      {/*      </div>*/}
                      {/*      <div className="message__item-name">*/}
                      {/*        <p>{data.id}</p>*/}
                      {/*      </div> *!/*/}
                      {/*  <div className="message__item-actions">*/}
                      {/*    <button*/}
                      {/*      className="message__delete feather feather-trash"*/}
                      {/*      onClick={() => this.onDelete(data.id)}*/}
                      {/*    ></button>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                    </div>
                  ))
                ) : (
                  <p className="dropdown__empty-content">
                    {translate.get("Yeni mesaj yoxdur")}
                  </p>
                )}
              </div>
            </div>
            <div className="custom__dropdown-footer">
              {count ? (
                <Link
                  to="/messages"
                  onClick={() => {
                    this.setState({ isDropdownShow: false });
                  }}
                >
                  {translate.get("Hamısına bax")}
                </Link>
              ) : <div
                  className='text-white'
                  style={{ cursor:'pointer' }}
                  onClick={async () => {
                    await this.setState({
                      isDropdownShow: !this.state.isDropdownShow,
                      new_messages_count: 0
                    })}}
              >
                {translate.get("Bağla")}
              </div>}
            </div>
          </div>
        ) : null}
      </header>
    );
  }
}






export class ComponentHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: this.props.link,
      onClick: this.props.onClick,
      screenWidth: window.innerWidth,

      target: this.props.target || '',
      search: this.props.searchable,
      input: true,
      logout: this.props.logout,

      deleted: this.props.deleted,
      allDelete: this.props.allDelete,

      msg: this.props.msg,
      new_messages_count: user.isAuth() ? user.getData().new_messages_count : 0,

      operator: this.props.operator,
      grid: this.props.grid,

      slide: this.props.slide,
      auth: this.props.auth
    };


    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }
  render() {
    let {
      search,
      input,
      link,
      deleted,
      allDelete,
      screenWidth,
      msg,
      new_messages_count,
      operator,
      grid,
      slide,
      auth,
      target,
      logout,
      onClick
    } = this.state;

    return (
      <div className="component__header">
        <div className='d-flex _jc-between flex-row w-100 ' >
          {
            this.props.back &&
            <Link to={{ pathname: this.props.back }}  className='go_back' >
              <img src="/assets/images/back_arrow.svg" alt="" className='mt-1' />
            </Link>
          }

          {
            this.props.title &&
            <div className="component__header-title">
              <h1 className='mt-1'>{this.props.title}</h1>
            </div>
          }

          <>
            {
              operator &&
              <div className="auth-operator-toogler">
                <img src="/assets/images/user.svg" alt=""/>
                <div className='d-flex flex-column'>
                  <span className="operator-number">{`0${user
                      .getData()
                      .msisdn.slice(3)}`}</span>
                  <span className="operator-name">
                    {
                      globalState
                          .get("operators")
                          .find(opr => opr.id === user.getData().operator)
                          .name
                    }
                  </span>
                </div>
              </div>
            }
          </>

          {
            screenWidth < 1025 ?
            <div className='d-flex' >
              {msg &&
                <div className="header__auth-messages">
                  <Link
                      to="/messages"
                      className="message_toogler"
                      onClick={async () => {
                        await this.setState({
                          isDropdownShow: !this.state.isDropdownShow,
                          new_messages_count: 0
                        });
                        if (this.state.isDropdownShow) {
                          this.reLoad();
                        }
                      }}
                  >
                    <img src='/assets/images/messenger.svg' alt=''/>
                    {new_messages_count ? (
                        <span className="message-count">
                              {new_messages_count}
                            </span>
                    ) : null}
                  </Link>
                </div>
            }

            {
              logout &&
              <div className='log-out-side' >
                <i className='feather feather-log-out' onClick={()=> user.logOut()} />
              </div>
            }

            {
              grid &&
              <Link to="/" className="header__menu-toogler" >
                <img src="/assets/images/grid.svg" alt="" />
                {/*<span>{translate.get("Menyu")}</span>*/}
              </Link>
            }
          </div> : <div />
          }
        </div>



        {
          slide &&
          <div className='slider-section__header' >
            <img src="/assets/images/logomob.svg" alt=""/>

            <div className='header__auth'>
              <a  href={"tel:1114"} className='contact_number custom-link' >
                <img src='/assets/images/phone_white.svg' alt="" />
                <span>1114</span>
              </a>
              <div className='d-flex' >
                <Link to={"/register"}  >
                  <button type={'button'} className='signin_btn mx-3' >{translate.get("Daxil ol / Qeydiyyat")}</button>
                </Link>
                {/*<Link to='/register'  >*/}
                {/*  <button type={'button'} className='signup_btn' >{translate.get("Hesaba giriş")}</button>*/}
                {/*</Link>*/}
              </div>
            </div>

          </div>
        }

        {
          auth &&
            <Link to={'/'} className='auth__header my-4' >
              <img src="/assets/images/logomob.svg" alt=""/>
            </Link>
        }

        {
          search ? (
          <div className="component__header-search">
            {input ? (
              <input
                autoFocus
                type="text"
                className="custom-input br"
                placeholder={translate.get(this.props.placeholder)}
                value={this.props.searchText}
                onChange={this.props.onSearch}
              />
            ) : null}
            {/* <button
              className="feather feather-search search__toggler"
              onClick={() => {
                this.setState({ input: !input });
              }}
            /> */}
          </div>
        ) : null
        }

        {
          link ? (
            <div className="component__header-link ml-3">
              <Link to={{pathname: link}} target={target} >
                <span className="custom-outline_btn btn br" >{translate.get(this.props.linkText)}</span>
              </Link>
            </div>
        ) : null
        }

        {
          onClick ? (
            <button onClick={onClick} className='component__header-btn btn' >
              <span style={{ whiteSpace: 'nowrap' }} >{translate.get(this.props.linkText)}</span>
            </button>
          ) : null
        }

        {
          !allDelete || deleted &&
          <div className='delete_side'>
            {
              allDelete &&
              <div className='component__header-delete-all' onClick={this.props.allDeleteFunc}>
                <img src="/assets/images/focus.svg" alt=""/>
                <p>Hamısını seç</p>
              </div>
            }

            {
              deleted &&
              <div className='component__header-delete' onClick={this.props.onMultiDelete}>
                <i className='feather feather-trash-2'/>
                <p>Sil</p>
              </div>
            }
          </div>
        }
      </div>
    );
  }
}
