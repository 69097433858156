import React from "react";
import {ComponentLoader, ComponentHeader, Verification} from "../../components";
import { translate, toast, globalState } from "../../lib";
import { accountChangeNumber_FS, accountChangeNumber_LS } from "../../actions";

export class Number extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      step: 1,

      operators: globalState.get("operators"),
      operator: "",

      prefixes: globalState.get("prefixes"),
      prefix: "",

      number: "",

      code: "",
      verify_hash: ""
    };
  }

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let { step, operator, prefix, number, code, verify_hash } = this.state;
    let params = null;

    if (step === 1) {
      params = { operator: parseInt(operator), prefix, number };
      let step1 = await accountChangeNumber_FS(params);
      if (step1) {
        this.setState({ isLoading: false });
        toast.fire({
          icon: step1.status === "success" ? "success" : "error",
          title: step1.description
        });
        if (step1.status === "success") {
          this.setState({ step: 2, verify_hash: step1.verify_hash });
        }
      }
    } else {
      params = { code, verify_hash };
      let step2 = await accountChangeNumber_LS(params);
      if (step2) {
        this.setState({ isLoading: false });
        toast.fire({
          icon: step2.status === "success" ? "success" : "error",
          title: step2.description
        });
        if (step2.status === "success") {
          setTimeout(() => {
            window.location = "/";
          }, 2000);
        }
      }
    }
  };

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  render() {
    let {
      isLoading,
      step,
      operators,
      operator,
      prefixes,
      prefix,
      number,
      code
    } = this.state;

    return (
      <>
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div
            ref={(el) => {
              if (el) {
                el.parentElement.style.background = "#fff";
              }
            }}
          >
          <div className="Number pb-5">
            <ComponentHeader
                title={translate.get("Nömrə dəyiş")}
                back={'/'}
                msg={true}
                grid={true}
            />
            <div className="number__content">
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  {step === 1 ? (
                    <>
                      <div className="col-md-12 mb-3">
                        <label className="custom-different-label">
                          {translate.get("Operator")}
                        </label>
                        <div className='select-container' >
                          <select
                            required
                            className="custom-different-select"
                            value={operator}
                            onChange={e => {
                              this.setState({ operator: e.target.value });
                            }}
                          >
                            <option value="" disabled={operator !== ""}>
                              {translate.get("Seçin")}
                            </option>
                            {operators.map((opr, i) => (
                              <option key={i} value={opr.id}>
                                {opr.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label className="custom-different-label">
                          {translate.get("Mobil nömrəniz")}
                        </label>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className='select-container' >
                          <select
                            required
                            className="custom-different-select"
                            value={prefix}
                            onChange={e => {
                              this.setState({ prefix: e.target.value });
                            }}
                          >
                            <option value="" disabled={prefix !== ""}>
                              {translate.get("Seçin")}
                            </option>
                            {prefixes.map((opr, i) => (
                              <option key={i} value={opr.text}>
                                {`0${opr.text}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-8 mb-3">
                        <input
                          required
                          maxLength="7"
                          type="text"
                          className="custom-different-input"
                          placeholder={translate.get("Nömrə")}
                          value={number}
                          onChange={e => {
                            this.setState({ number: e.target.value });
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="col-md-12 mb-4">
                      <label className="custom-different-label">
                        {translate.get("Təsdiq kodu")}
                      </label>

                      <Verification
                          onChange={e => {
                            this.setState({ code: e });
                          }}
                          className={'number-verification'}
                      />

                      {/*<input*/}
                      {/*  required*/}
                      {/*  maxLength="6"*/}
                      {/*  type="text"*/}
                      {/*  className="custom-different-input"*/}
                      {/*  value={code}*/}
                      {/*  onChange={e => {*/}
                      {/*    this.setState({ code: e.target.value });*/}
                      {/*  }}*/}
                      {/*/>*/}
                    </div>
                  )}

                  <div className="col-md-12">
                    <button type="submit" className="custom-btn w-100">
                      {step === 1
                        ? translate.get("Davam et")
                        : translate.get("Təsdiqlə")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          </div>
        )}
      </>
    );
  }
}
