import {api} from '../lib';

export async function carsList(params) {
  return await api.get('carsList', params);
}

export async function carsMinList(params) {
  return await api.get('carsMinList', params);
}

export async function carsActivate(params) {
  return await api.get('carsActivate', params);
}

export async function carsDeactivate(params) {
  return await api.get('carsDeactivate', params);
}

export async function carsDelete(params) {
  return await api.get('carsDelete', params);
}

export async function carsInfo(params) {
  return await api.get('carsInfo', params);
}

export async function carsCharge(params) {
  return await api.get('carsCharge', params);
}

export async function carsEdit(params) {
  return await api.get('carsEdit', params);
}

export async function carsInsuranceCheck(params) {
  return await api.get('carsInsuranceCheck', params);
}

export async function carsInsuranceCompanies(params) {
  return await api.get('carsInsuranceCompanies', params);
}

export async function carsInsuranceSet(params) {
  return await api.get('carsInsuranceSet', params);
}