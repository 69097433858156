import React from "react";
import { Link } from "react-router-dom";
import {ComponentHeader, InputPassword, Loader} from "../../components";
import { Modal } from "./components";
import { translate, toast, cookie, globalState, functions } from "../../lib";
import { accountSignIn, showModal, hideModal } from "../../actions";
import $ from "jquery";

export class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      modals: [],
      infoType: "",

      number: "",
      password: "",
      prefix: "",
      prefixes: globalState.get("prefixes"),
      remember: false,

      screenWidth: window.innerWidth,
    };
    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });
  }

  onSubmit = async e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let { number, password, prefix, remember } = this.state;
    let params = { msisdn: `994${prefix + number}`, password, withCredentials: true };

    let response = await accountSignIn(params);
    if (response) {
      if (response.status === "success") {
        // cookie.set("_token", response.token, remember ? 30 : 1);
        // cookie.set("_hash", response.hash, remember ? 30 : 1);
        // window.location = "/";
      } else {
        this.setState({ isLoading: false });
        toast.fire({
          icon: response.status === "success" ? "success" : "error",
          title: response.description
        });
      }
    }
  };

  render() {
    let { isLoading, prefixes, prefix, modals, infoType, screenWidth } = this.state;

    return (
      <div className='content_min' >
        {screenWidth < 1025 && <ComponentHeader auth={true}/>}
        {isLoading ? (
          <Loader />
        ) : (
          <div className="Login">
            {functions.in_array("modal", modals) && (
              <Modal
                type={infoType}
                onClose={() => {
                  this.setState({ infoType: "" });
                  hideModal.bind(this)("modal");
                }}
              />
            )}

            <div className="content_side">
              <h1 className="content-title mb-2">
                {translate.get("Daxil ol")}
              </h1>
              <form onSubmit={this.onSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <label className="custom-label">
                      {translate.get("Nömrə")}
                    </label>
                  </div>
                  <div className="col-4 mb-2">
                    <select
                      required
                      className="custom-select"
                      value={prefix}
                      onChange={e => {
                        this.setState({ prefix: e.target.value });
                      }}
                    >
                      <option value="" disabled={prefix !== ""}>
                        {translate.get("Seçin")}
                      </option>
                      {prefixes.map((pre, i) => (
                        <option value={pre.text} key={i}>
                          {`0${pre.text}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-8 mb-2">
                    <input
                      required
                      autoFocus
                      maxLength="7"
                      type="text"
                      className="custom-input"
                      placeholder={translate.get("Nömrə")}
                      value={this.state.number}
                      onChange={e => {
                        this.setState({ number: e.target.value });
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12 mb-2">
                    <label className="custom-label">
                      {translate.get("Şifrə")}
                    </label>
                    <InputPassword
                        required
                        placeholder="Şifrəniz"
                        required={true}
                        value={this.state.password}
                        onChange={(e) =>
                            this.setState({ password: e.target.value })
                        }
                        className='text-white'
                    />
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <button type="submit" className="btn light-btn br w-100 my-3">
                      {translate.get("Daxil ol")}
                    </button>
                  </div>

                  <div className="col-md-12 mb-2 d-flex justify-content-between">
                    <label className="custom-checkbox">
                      <input
                          type="checkbox"
                          checked={this.state.remember}
                          onChange={() => {
                            this.setState({ remember: !this.state.remember });
                          }}
                      />
                      <span className="checkmark"></span>
                      <p className="title">{translate.get("Yadda saxla")}</p>
                    </label>
                    <Link to="/forgot" className="custom-link">
                      {translate.get("Şifrəni unutdun?")}
                    </Link>
                  </div>

                </div>
              </form>

              <div className="extra-links mt-3">
                <button
                  className={`custom-btn br ${
                    infoType === "info" ? "selected" : ""
                  }`}
                  onClick={async () => {
                    await this.setState({ infoType: "info" });
                    showModal.bind(this)("modal");
                  }}
                >
                  <img alt="" src="/assets/images/info_green.svg" />
                  {translate.get("Məlumat")}
                </button>

                <button
                  className={`custom-btn br ${
                    infoType === "call" ? "selected" : ""
                  }`}
                  onClick={async () => {
                    await this.setState({ infoType: "call" });
                    showModal.bind(this)("modal");
                  }}
                >
                  <img alt="" src="/assets/images/phone_green.svg" />
                  {translate.get("Əlaqə")}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
