import React from 'react';
import {ComponentLoader, ComponentHeader, Warn, Icon} from '../../components';
import {licenseAdd} from '../../actions';
import {translate, globalState, toast} from '../../lib';

export class LicenseAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      days: [],
      months: globalState.get('months'),
      years: [],

      series: '',
      expire_day: '',
      expire_month: '',
      expire_year: '',

      birth_day: '',
      birth_month: '',
      birth_year: '',
    };
  }

  reLoad = () => {
    let date = new Date().getFullYear();
    let years = [];
    for (let i = date; i <= date + 16; i++) {
      years.push(i);
    }
    this.setState({years});
    this.loadDaysbyMonth(this.state.expire_month, this.state.expire_year);
  };

  onSubmit = async e => {
    e.preventDefault();
    this.setState({isLoading: true});

    let {
      series,
      expire_day,
      expire_month,
      expire_year,
      birth_day,
      birth_month,
      birth_year,
    } = this.state;
    let params = {
      series,
      expire_day,
      expire_month,
      expire_year,
      birth_day,
      birth_month,
      birth_year,
    };

    let response = await licenseAdd(params);
    if (response) {
      this.setState({isLoading: false});
      toast.fire({
        icon: response.status === 'success' ? 'success' : 'error',
        title: response.description,
      });
      if (response.status === 'success') {
        setTimeout(() => {
          window.location = '/';
        }, 2000);
      }
    }
  };

  loadDaysbyMonth = (month, year) => {
    let dayCount = new Date(year, month, 0).getDate();
    let days = [];
    for (let i = 1; i <= dayCount; i++) {
      days.push(i);
    }
    this.setState({days});
  };

  componentDidMount() {
    this.reLoad();
  }

  render() {
    let {
      isLoading,
      series,
      days,
      months,
      years,
      expire_day,
      expire_month,
      expire_year,
      birth_day,
      birth_month,
      birth_year,
    } = this.state;

    return (
      <div className="content" style={{background: '#fff'}}>
        <div className="LicenseAdd">
          <ComponentHeader
            title={translate.get('Yeni vəsiqə')}
            back={'/licenses'}
            grid={true}
            msg={true}
          />
          <div className="license-add__content">
            <form onSubmit={this.onSubmit} className="mb-5">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="custom-different-label">
                    {translate.get('Seriya №-si')}
                  </label>
                  <input
                    required
                    type="text"
                    className="custom-different-input"
                    placeholder={'AB000000'}
                    value={series}
                    onChange={e => {
                      this.setState({series: e.target.value});
                    }}
                  />
                </div>

                <div className="col-md-12">
                  <label className="custom-different-label">
                    {translate.get('Etibarlıdır')}
                  </label>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="select-container">
                    <select
                      required
                      className="custom-different-select"
                      value={expire_day}
                      onChange={e => {
                        this.setState({expire_day: e.target.value});
                      }}
                    >
                      <option value="" disabled={expire_day !== ''}>
                        {translate.get('Gün')}
                      </option>
                      {days.map((d, i) => (
                        <option key={i} value={d}>
                          {d < 10 ? `0${d}` : d}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="select-container">
                    <select
                      required
                      className="custom-different-select"
                      value={expire_month}
                      onChange={e => {
                        this.setState({
                          expire_month: e.target.value,
                        });
                        this.loadDaysbyMonth(e.target.value, expire_year);
                      }}
                    >
                      <option value="" disabled={expire_month !== ''}>
                        {translate.get('Ay')}
                      </option>
                      {months.map((m, i) => (
                        <option key={i} value={i + 1}>
                          {m}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="select-container">
                    <select
                      required
                      className="custom-different-select"
                      value={expire_year}
                      onChange={e => {
                        this.setState({expire_year: e.target.value});
                        this.loadDaysbyMonth(expire_month, e.target.value);
                      }}
                    >
                      <option value="" disabled={expire_year !== ''}>
                        {translate.get('İl')}
                      </option>
                      {years.map((y, i) => (
                        <option key={i} value={y}>
                          {y}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                {/*<div className="col-md-12">*/}
                {/*  <label className="custom-different-label">*/}
                {/*    {translate.get('Doğum tarixi')}*/}
                {/*  </label>*/}
                {/*</div>*/}

                {/*<div className="col-md-4 mb-4">*/}
                {/*  <div className="select-container">*/}
                {/*    <select*/}
                {/*      required*/}
                {/*      className="custom-different-select"*/}
                {/*      value={birth_day}*/}
                {/*      onChange={e => {*/}
                {/*        this.setState({birth_day: e.target.value});*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <option value="" disabled={birth_day !== ''}>*/}
                {/*        {translate.get('Gün')}*/}
                {/*      </option>*/}
                {/*      {days.map((d, i) => (*/}
                {/*        <option key={i} value={d}>*/}
                {/*          {d < 10 ? `0${d}` : d}*/}
                {/*        </option>*/}
                {/*      ))}*/}
                {/*    </select>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-4 mb-4">*/}
                {/*  <div className="select-container">*/}
                {/*    <select*/}
                {/*      required*/}
                {/*      className="custom-different-select"*/}
                {/*      value={birth_month}*/}
                {/*      onChange={e => {*/}
                {/*        this.setState({*/}
                {/*          birth_month: e.target.value,*/}
                {/*        });*/}
                {/*        this.loadDaysbyMonth(e.target.value, birth_year);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <option value="" disabled={birth_month !== ''}>*/}
                {/*        {translate.get('Ay')}*/}
                {/*      </option>*/}
                {/*      {months.map((m, i) => (*/}
                {/*        <option key={i} value={i + 1}>*/}
                {/*          {m}*/}
                {/*        </option>*/}
                {/*      ))}*/}
                {/*    </select>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div className="col-md-4 mb-4">*/}
                {/*  <div className="select-container">*/}
                {/*    <select*/}
                {/*      required*/}
                {/*      className="custom-different-select"*/}
                {/*      value={birth_year}*/}
                {/*      onChange={e => {*/}
                {/*        this.setState({birth_year: e.target.value});*/}
                {/*        this.loadDaysbyMonth(birth_month, e.target.value);*/}
                {/*      }}*/}
                {/*    >*/}
                {/*      <option value="" disabled={birth_year !== ''}>*/}
                {/*        {translate.get('İl')}*/}
                {/*      </option>*/}
                {/*      {years.map((y, i) => (*/}
                {/*        <option key={i} value={y}>*/}
                {/*          {y}*/}
                {/*        </option>*/}
                {/*      ))}*/}
                {/*    </select>*/}
                {/*  </div>*/}
                {/*</div>*/}

                <div className="col-md-12">
                  <button type="submit" className="custom-btn w-100">
                    {isLoading
                      ? <Icon/>
                      : translate.get('Əlavə et')
                    }
                  </button>
                </div>
              </div>
              <div className="mt-5">
                <Warn
                  desc={'Sürücülük vəsiqəsini əlavə etmək ödənişlidir, 3 aylıq 1 AZN.'}
                  warn={true}
                  br={'#F3EBCF'}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
