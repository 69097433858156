
export const API_URL = 'https://smsradar.az/apixxx';

export const API_ROUTES = {
  //Account
  authCheck: API_URL + '/auth/check',
  accountSignIn: API_URL + '/auth/signin',
  accountInfo: API_URL + '/account/info',
  accountSettings: API_URL + '/settings',
  accountLogout: API_URL + '/account/logout',

  // old registration API's
  accountSignUp_FS: API_URL + '/caradd/step1',
  accountSignUp_LS: API_URL + '/caradd/step2',

  // new registration API's
  accountRegister_FS: API_URL + '/register/step1',
  accountRegister_LS: API_URL + '/register/finish',

  accountForgotPassword: API_URL + '/auth/forget',
  accountChangePassword: API_URL + '/account/password',
  accountChangeNumber_FS: API_URL + '/number/updatenumber1',
  accountChangeNumber_LS: API_URL + '/number/updatenumber2',

  //Law items
  lawItemsList: API_URL + '/items/list',

  //Messages
  messageList: API_URL + '/messages/list',
  messageDelete: API_URL + '/messages/delete',
  messageMultiDelete: API_URL + '/messages/deletemulti',

  //Balance
  balanceAddByAzCard: API_URL + '/balance/addbyazcard',

  //Cars
  carsList: API_URL + '/cars/list',
  carsMinList: API_URL + '/cars/minlist',
  carsActivate: API_URL + '/cars/activate',
  carsDeactivate: API_URL + '/cars/deactivate',
  carsDelete: API_URL + '/cars/delete',
  carsInfo: API_URL + '/cars/info',
  carsEdit: API_URL + '/cars/edit',
  carsCharge: API_URL + '/cars/charge',
  carsAdd: API_URL + '/caradd',

  // Insurance
  carsInsuranceCheck: API_URL + '/insurance/check',
  carsInsuranceCompanies: API_URL + '/insurance/companies',
  carsInsuranceSet: API_URL + '/cars/setinsurance',

  //Questions
  questionList: API_URL + '/questions/list',
  questionAsk: API_URL + '/questions/ask',
  questionDelete: API_URL + '/questions/delete',
  questionReply: API_URL + '/questions/reply',

  //Protocols
  protocolList: API_URL + '/protocols/list',
  protocolPay: API_URL + '/protocols/pay',
  protocolFiles: API_URL + '/protocols/images',
  protocolExport: API_URL + '/protocols/export',

  //License
  licenseList: API_URL + '/driverlicenses/list',
  licenseAdd: API_URL + '/driverlicenses/add',
  licenseDelete: API_URL + '/driverlicenses/delete',
  licenseCheck: API_URL + '/driverlicenses/check',
  licenseInfo: API_URL + '/driverlicenses/info',
  licenseActivate: API_URL + '/driverlicenses/activate',
  licenseDeactivate: API_URL + '/driverlicenses/deactivate',

  // exam
  examStart: API_URL + '/exam/start',
  examFinish: API_URL + '/exam/finish',
  examStatus: API_URL + '/exam/status',
  examSetAnswer: API_URL + '/exam/setanswer',
  examSubscribe: API_URL + '/exam/subscribe',
  examUnsubscribe: API_URL + '/exam/unsubscribe',

  // history
  historyList: API_URL + '/history/list',
  historyInfo: API_URL + '/history/info',

  // payments
  paymentsAdd: API_URL + '/card/add',
  paymentsList: API_URL + '/paymentmethods/list',
  paymentsUpdate: API_URL + '/paymentmethods/setdefault',
  paymentsDelete: API_URL + '/paymentmethods/delete',
};
