import React from 'react';
import {Modal} from 'react-bootstrap';
import {Link} from 'react-router-dom';


export const GetInsurance = ({getInsurance, onClose}) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={getInsurance}
      onHide={onClose}
    >
      <Modal.Header>
        <div className='d-flex justify-content-between align-items-center w-100' >
          <h5 className='mb-0' >İcbari sığortanı bizdən alın</h5>
          <button
            className="close-btn feather feather-x"
            onClick={onClose}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p style={{ color: "#777" }} className='text-center' >
          Həftə içi hər gün 24 saat ərzində bizimlə əlaqə saxlayaraq avtomobilinizin icbari siğortasını yeniləyə bilərsiniz
        </p>
        <div className='get_insurance row' >
          <div className='col-md-6' >
            <div className='i_item' >
              <img alt="" src="/assets/images/call_bg.png" />
              <Link
                target="blank"
                className="custom-link"
                to={{ pathname: "tel:+9940504445454" }}
              >
                (050) 444 54 54
              </Link>
            </div>
          </div>
          <div className='col-md-6' >
          <div className='i_item' >
            <img alt="" src="/assets/images/call_bg.png" />
            <Link
              target="blank"
              className="custom-link"
              to={{ pathname: "tel:+9940504444488" }}
            >
              (050) 444 44 88
            </Link>
          </div>
          </div>
          <div className="w-100 px-3 mt-3" >
            <button className='custom-btn btn-block' onClick={onClose} >
              Bağla
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}