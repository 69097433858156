import React from "react";

import { Link } from "react-router-dom";
import { ComponentLoader, ComponentHeader, Sidebar } from "../../components";
import { translate, globalFunctions, toast } from "../../lib";
import {
  carsList,
  carsActivate,
  carsDeactivate,
  carsDelete,
  carsInfo,
  carsCharge,
  carsInsuranceCheck,
  carsInsuranceCompanies,
} from "../../actions";
import Swal from "sweetalert2";
import $ from "jquery";
import { Insurance } from "./Insurance";
import { GetInsurance } from "./GetInsurance";
import { NotInsuranceModal } from "./NotInsuranceModal";
import { Activity } from "./Activity";

export class CarList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isScrollLoading: false,
      isSearchLoading: false,
      getInsurance: false,
      modal: false,
      NoInsurance: false,
      activity: false,
      activityLoading: false,

      NoInsuranceTitle: "",

      limit: 50,
      offset: 0,
      keyword: "",

      url: "",
      urlStatus: "",
      cars: [],
      count: 0,
      insurances: [],

      car: null,

      screenWidth: window.innerWidth,
    };

    $(window).resize(() => {
      this.setState({ screenWidth: $(window).innerWidth() });
    });

    globalFunctions.set({
      refreshCarData: async (id, action) => {
        this.setState({ isLoading: true });
        let response = await carsInfo({ id, action });
        if (response) {
          this.setState({ isLoading: false });
          toast.fire({
            icon: response.status === "success" ? "success" : "error",
            title:
              response.status === "success"
                ? "Uğurla yeniləndi"
                : "Xəta baş verdi",
          });
          if (response.status === "success") {
            let cars = this.state.cars;
            let car = response.data;
            console.log("car", car);
            car[`canRefresh_${action}`] = false;
            cars = cars.map((c) => {
              if (c.id === car.id) {
                c = { ...car, car_number: c.car_number };
              }
              return c;
            });
            this.setState({ car, cars });
          }
        }
      },
      refreshSubscriptionData: async (id, action) => {
        this.setState({ isLoading: true });
        let response = await carsCharge({ id });
        this.setState({ isLoading: false });
        toast.fire({
          icon: response.status === "success" ? "success" : "error",
          title:
            response.status === "success"
              ? "Uğurla yeniləndi"
              : response.description,
        });
        if (response.status === "success") {
          let cars = this.state.cars;
          let car = { ...this.state.car, ...response.data };
          car[`canRefresh_${action}`] = false;
          cars = cars.map((c) => {
            if (c.id === car.id) {
              c = { ...car, car_number: c.car_number };
            }
            return c;
          });
          this.setState({ car, cars });
        }
      },
      carsDeactivate: async (data) => {
        this.setState({ activityLoading: true });
        let response = carsDeactivate({ id: data.id });
        if (response) {
          this.setState({ activityLoading: false });
        }
      },
      carsActivate: async (data) => {
        let response = carsActivate({ id: data.id });
        if (response.status === "success") {
          this.reLoad();
        }
      },
      deleteCar: async (id) => {
        Swal.fire({
          title: translate.get("Doğrulayın"),
          text: translate.get("Maşını silmək istədiyinizə əminsinizmi?"),
          icon: null,
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonClass: "custom-btn success-btn",
          cancelButtonClass: "custom-btn default-btn",
          confirmButtonText: translate.get("Bəli"),
          cancelButtonText: translate.get("Xeyr"),
        }).then(async (res) => {
          if (res && res.value) {
            this.setState({
              isLoading: true,
            });

            let respoonse = await carsDelete({ id });
            if (respoonse) {
              this.setState({ isLoading: false });
              toast.fire({
                icon: respoonse.status === "success" ? "success" : "error",
                title: respoonse.description,
              });
              if (respoonse.status === "success") {
                this.reLoad();
              }
            }
          }
        });
      },
      onExport: async () => {
        console.log(this.state);
      },
    });
  }

  loadAgreement = async (data) => {
    let response = await carsInsuranceCheck({ id: data?.id });
    if (response) {
      this.setState({ urlStatus: response.status });
      if (response.status === "success") {
        this.setState({ url: response.data?.url });
      } else {
        this.setState({ url: response.description });
      }
    }
  };

  loadInsurances = async () => {
    let response = await carsInsuranceCompanies();
    if (response.status === "success") {
      this.setState({ insurances: response.data });
    } else {
      this.setState({ insurances: [] });
    }
  };

  reLoad = async () => {
    this.setState({ isLoading: true });

    let { limit, offset, car, keyword } = this.state;
    let params = { limit, offset, keyword };
    let cars = await carsList(params);
    if (cars) {
      this.setState({ isLoading: false });
      if (cars.status === "success") {
        this.setState({ cars: cars.data, count: cars.count });
        if (car) {
          let currentCar = cars.data.find((c) => c.id === car.id);
          this.setState({ car: currentCar });
        }
      }
    }
  };

  componentDidMount() {
    this.reLoad();
    this.loadInsurances();
  }

  onGet = (data) => {
    if (data) {
      this.setState({ car: data });
      this.loadAgreement(data);
    } else {
      this.setState({ car: null });
    }
  };

  onSearch = async (e) => {
    if (e.target.value.trim().length >= 5) {
      await this.setState({ keyword: e.target.value });

      this.setState({ isSearchLoading: true });
      let { keyword, limit } = this.state;
      let params = { keyword, limit };
      let cars = await carsList(params);
      if (cars) {
        this.setState({ isSearchLoading: false });
        if (cars.status === "success") {
          this.setState({ cars: cars.data, count: cars.count });
        } else {
          this.setState({ cars: [], count: 0 });
          toast.fire({
            toast: true,
            position: "top",
            icon: "error",
            title: cars.description,
            timer: 1000,
          });
        }
      }
    } else if (e.target.value.trim().length === 0) {
      await this.setState({ keyword: "" });

      this.reLoad();
    }
  };

  onScroll = async () => {
    let elmnt = document.querySelector(".sidebar__item-container");
    let scrollableHeight = elmnt.scrollHeight - elmnt.clientHeight;
    let percent = ((elmnt.scrollTop / scrollableHeight) * 100).toFixed();

    if (
      !this.state.isScrollLoading &&
      percent >= 100 &&
      this.state.offset + this.state.limit <= this.state.count
    ) {
      let skip = this.state.offset;
      await this.setState({ offset: (skip += 50) });

      this.setState({ isScrollLoading: true });
      let { limit, offset, keyword } = this.state;
      let params = { limit, offset, keyword };
      let response = await carsList(params);
      if (response) {
        this.setState({ isScrollLoading: false });
        if (response.status === "success") {
          let cars = this.state.cars;
          this.setState({ cars: [...cars, ...response.data] });
        } else {
          toast.fire({
            icon: "error",
            title: response.description,
          });
        }
      }
    }
  };

  render() {
    let {
      isLoading,
      isScrollLoading,
      isSearchLoading,
      cars,
      car,
      screenWidth,
      modal,
      url,
      urlStatus,
      getInsurance,
      NoInsurance,
      activity,
      activityLoading,
      insurances,
    } = this.state;

    let insuranceCompany =
      insurances.find((x) => x.id === car?.car_insurer) || "";
    return (
      <div className="content">
        <ComponentHeader
          title={translate.get("Avtomobillərim")}
          searchable={true}
          onSearch={this.onSearch}
          placeholder="Axtarın (min. 5 simvol)"
          back={"/"}
          msg={true}
          grid={true}
        />
        <Insurance
          onClose={() => this.setState({ modal: false })}
          addModal={modal}
          car={car}
          refresh={() => this.reLoad()}
        />
        <GetInsurance
          onClose={() => this.setState({ getInsurance: false })}
          getInsurance={getInsurance}
        />
        <NotInsuranceModal
          onClose={() => this.setState({ NoInsurance: false })}
          NoInsurance={NoInsurance}
          url={url}
        />
        <Activity
          onClose={() => this.setState({ activity: false })}
          activity={activity}
          activityLoading={activityLoading}
          car={car}
        />
        {isLoading ? (
          <ComponentLoader />
        ) : (
          <div className="Cars">
            <div className="cars__content">
              <Sidebar
                searchable={true}
                car={car}
                data={cars}
                type={"cars"}
                onGet={this.onGet}
                onScroll={this.onScroll}
                onSearch={this.onSearch}
                isScrollLoading={isScrollLoading}
                isSearchLoading={isSearchLoading}
                url={url}
                openModal={() => this.setState({ modal: true })}
                getInsuranceModal={() => this.setState({ getInsurance: true })}
                IfNoInsurance={() => this.setState({ NoInsurance: true })}
                urlStatus={urlStatus}
                activityModal={() => {
                  this.setState({ activity: true });
                  globalFunctions._carsDeactivate(car);
                }}
                insuranceCompany={insuranceCompany}
              />
              {screenWidth > 1024 ? (
                <div className="cars__container">
                  <div className="cars__content-main">
                    {car ? (
                      <ContentCars
                        object={car}
                        url={url}
                        openModal={() => this.setState({ modal: true })}
                        getInsuranceModal={() =>
                          this.setState({ getInsurance: true })
                        }
                        IfNoInsurance={() =>
                          this.setState({ NoInsurance: true })
                        }
                        activityModal={() => {
                          this.setState({ activity: true });
                          globalFunctions._carsDeactivate(car);
                        }}
                        urlStatus={urlStatus}
                        insuranceCompany={insuranceCompany}
                      />
                    ) : cars.length === 0 ? (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                        <img src="/assets/images/noCar.svg" alt="" />
                        <p
                          style={{
                            color: "#16A085",
                            margin: "2em 0",
                          }}
                        >
                          Avtomobil əlavə olunmayıb..
                        </p>
                        <Link to="/cars/add">
                          <button className="custom-btn ">
                            Avtomobil əlavə et
                          </button>
                        </Link>
                      </div>
                    ) : (
                      <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5 mt-5">
                        <img src="/assets/images/noCar.svg" alt="" />
                        <p
                          style={{
                            color: "#16A085",
                            margin: "1em 0",
                          }}
                        >
                          Avtomobil seçin
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              ) : cars.length === 0 ? (
                <div className="cars__container">
                  <div className="cars__content-main">
                    <div className="d-flex flex-column align-items-center justify-content-center h-100 pt-5">
                      <img
                        src="/assets/images/noCar.svg"
                        alt=""
                        style={{
                          objectFit: "contain",
                          width: screenWidth < 359 ? "100%" : "",
                        }}
                      />
                      <p
                        style={{
                          color: "#16A085",
                          margin: "2em 0",
                          textAlign: "center",
                        }}
                      >
                        Avtomobil əlavə olunmayıb..
                      </p>
                      <Link to="/cars/add">
                        <button className="custom-btn ">
                          Avtomobil əlavə et
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const ContentCars = (props) => {
  let {
    object,
    url,
    openModal,
    getInsuranceModal,
    urlStatus,
    IfNoInsurance,
    activityModal,
    insuranceCompany,
  } = props;
  return (
    <React.Fragment>
      {object.tech_id || object.created_date || object.mark || object.year ? (
        <div className="custom-row">
          {object.tech_id ? (
            <div className="custom-row__data">
              <p className="data-title">
                {translate.get("Tex pasport seriya və nömrəsi:")}
              </p>
              <p className="data-content">
                {object.tech_id}
                {object.tech_changed ? (
                  <Link
                    to={{ pathname: `cars/edit/${object.id}` }}
                    className={`link btn btn-link`}
                  >
                    ({translate.get("Dəyiş")})
                  </Link>
                ) : null}
              </p>
            </div>
          ) : null}

          {object.created_date ? (
            <div className="custom-row__data">
              <p className="data-title">{translate.get("Qeydiyyat tarixi:")}</p>
              <p className="data-content">{object.created_date}</p>
            </div>
          ) : null}

          {object.mark ? (
            <div className="custom-row__data">
              <p className="data-title">{translate.get("Marka/Model:")}</p>
              <p className="data-content">{object.mark}</p>
            </div>
          ) : null}

          {object.year ? (
            <div className="custom-row__data">
              <p className="data-title">{translate.get("Buraxılış ili:")}</p>
              <p className="data-content">{object.year}</p>
            </div>
          ) : null}
        </div>
      ) : null}

      {!!object.show_tech && (
        <div className="custom-row">
          <div className="custom-row__data justify-content-between">
            <p className="data-title">{translate.get("İcbari sığortası:")}</p>
            <p className="data-content">
              {object.insurance === 1
                ? "Var"
                : object.insurance === 2
                ? "Yoxlanılmayıb"
                : "Yoxdur"}
              {object.show_insurance ? (
                !object.hasOwnProperty("canRefresh_insurance") ||
                object.canRefresh_insurance ? (
                  <button
                    className={`link btn btn-link pr-1 ${object.id}`}
                    onClick={() =>
                      globalFunctions._refreshCarData(object.id, "insurance")
                    }
                  >
                    ({translate.get("Yenilə")})
                  </button>
                ) : null
              ) : null}
            </p>
          </div>

          <div className="custom-row__data">
            <p className="data-title">{translate.get("İcbari sığortanı:")}</p>
            <button
              className={`link btn btn-link pl-0`}
              style={{ fontSize: "0.9em" }}
              onClick={getInsuranceModal}
            >
              {translate.get("Bizdən al")}
            </button>
          </div>

          <div className="custom-row__data">
            <p className="data-title">
              {translate.get("İcbari sığorta müqaviləsini:")}
            </p>
            {urlStatus === "success" ? (
              <a
                className={`link btn btn-link pl-0`}
                style={{ fontSize: "0.9em" }}
                target="_blank"
                href={url}
              >
                {translate.get("Yüklə")}
              </a>
            ) : (
              <button
                className={`link btn btn-link pl-0`}
                style={{ fontSize: "0.9em" }}
                onClick={IfNoInsurance}
              >
                {translate.get("Yüklə")}
              </button>
            )}
          </div>

          {!!object.insurance_date && (
            <div className="custom-row__data">
              <p className="data-title">
                {translate.get("İcbari sığortanın bitmə vaxtı:")}
              </p>
              <p className="data-content">
                {object.insurance_date}
                <button
                  className={`link btn btn-link pl-0 ml-2`}
                  style={{ fontSize: "0.9em" }}
                  onClick={openModal}
                >
                  ({translate.get("Dəyiş")})
                </button>
              </p>
            </div>
          )}

          {object.show_insurance ? (
            <>
              {object.car_insurer ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("Sığorta şirkəti:")}
                  </p>
                  <p className="data-content">
                    {insuranceCompany?.title || "Yüklənir..."}
                  </p>
                </div>
              ) : null}

              {object.car_insurance_doc ? (
                <div className="custom-row__data">
                  <p className="data-title">
                    {translate.get("Sığorta muqaviləsi:")}
                  </p>
                  <p className="data-content">{object.car_insurance_doc}</p>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      )}

      {object.has_arrest ? (
        <div className="custom-row">
          <div className="custom-row__data">
            <p className="data-title">{translate.get("Məhdudiyyət (Həbs):")}</p>
            <p className="data-content">
              {object.has_arrest}
              {object.show_arrest ? (
                !object.hasOwnProperty("canRefresh_arrest") ||
                object.canRefresh_arrest ? (
                  <button
                    className={`link btn btn-link ${object.id}`}
                    onClick={() =>
                      globalFunctions._refreshCarData(object.id, "arrest")
                    }
                  >
                    ({translate.get("Yenilə")})
                  </button>
                ) : null
              ) : null}
            </p>
          </div>
        </div>
      ) : null}

      {/*{object.show_tech ? (*/}
      <div className="custom-row">
        {/* {object.tech_review_date ? (
            <div className="custom-row__data">
              <p className="data-title">
                {translate.get("Texniki baxışdan keçmə vaxtı:")}
              </p>
              <p className="data-content">
                {object.tech_review_date}
              </p>
            </div>
          ) : null} */}

        {object.show_tech ? (
          <div className="custom-row__data">
            <p className="data-title">
              {translate.get("Texniki baxışın bitmə vaxtı:")}
            </p>
            <p className="data-content">
              {object.tech_exp_date}
              {object.show_tech ? (
                !object.hasOwnProperty("canRefresh_tech") ||
                object.canRefresh_tech ? (
                  <button
                    className={`link btn btn-link ${object.id}`}
                    onClick={() =>
                      globalFunctions._refreshCarData(object.id, "tech")
                    }
                  >
                    ({translate.get("Yenilə")})
                  </button>
                ) : null
              ) : null}
            </p>
          </div>
        ) : null}

        {/*{object.subscription_exp_date ? (*/}
        <div className="custom-row__data">
          <p className="data-title">
            {translate.get("SMSRadarın bitmə vaxtı:")}
          </p>
          <p className="data-content">
            {object.subscription_exp_date}
            {!object.hasOwnProperty("canRefresh_charge") ||
            object.canRefresh_charge ? (
              <button
                className={`link btn btn-link ${object.id}`}
                onClick={() =>
                  globalFunctions._refreshSubscriptionData(object.id, "charge")
                }
              >
                ({translate.get("Yenilə")})
              </button>
            ) : null}
          </p>
        </div>
        {/*) : null}*/}
      </div>
      {/*) : null}*/}
      <div className="row">
        <div className="col-md-12 p-md-3">
          {object.status !== 0 ? (
            <button
              onClick={activityModal}
              className="custom-btn danger-btn br my-2"
            >
              {translate.get("Deaktiv et")}
            </button>
          ) : (
            <button
              onClick={() => globalFunctions._carsActivate(object)}
              className="custom-btn success-btn br my-2"
            >
              {translate.get("Aktiv et")}
            </button>
          )}

          {/* <button
            className="custom-btn danger-btn br m-2"
            onClick={() => globalFunctions._deleteCar(object.id)}
          >
            {translate.get("Sil")}
          </button> */}
        </div>
      </div>
    </React.Fragment>
  );
};
