import React from "react";
import { ComponentHeader, Warn, Icon } from "../../components";
import { ContentLicense } from "../License";
import { licenseCheck } from "../../actions";
import { translate, globalState, toast } from "../../lib";

export class Point extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      license: null,

      days: [],
      months: globalState.get("months"),
      years: [],

      series: "",

      expire_day: "",
      expire_month: "",
      expire_year: "",

      birth_day: "",
      birth_month: "",
      birth_year: "",
    };
  }

  reLoad = () => {
    let date = new Date().getFullYear();
    let years = [];
    for (let i = date; i <= date + 16; i++) {
      years.push(i);
    }
    this.setState({ years });
    this.loadDaysbyMonth(this.state.expire_month, this.state.expire_year);
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let {
      series,
      expire_day,
      expire_month,
      expire_year,
      birth_day,
      birth_month,
      birth_year,
    } = this.state;
    let params = {
      series,
      expire_day,
      expire_month,
      expire_year,
      birth_day,
      birth_month,
      birth_year,
    };

    let response = await licenseCheck(params);
    if (response) {
      this.setState({ isLoading: false });

      if (response.status === "success") {
        this.setState({
          license: response,
          series: "",
        });
      } else {
        toast.fire({
          icon: response.status === "success" ? "success" : "error",
          title: response.description,
        });
      }
    }
  };

  loadDaysbyMonth = (month, year) => {
    let dayCount = new Date(year, month, 0).getDate();
    let days = [];
    for (let i = 1; i <= dayCount; i++) {
      days.push(i);
    }
    this.setState({ days });
  };

  componentDidMount() {
    this.reLoad();
  }

  render() {
    let {
      isLoading,
      license,
      series,
      days,
      months,
      years,
      expire_day,
      expire_month,
      expire_year,
      birth_day,
      birth_month,
      birth_year,
    } = this.state;

    return (
      <>
        <ComponentHeader
          title={translate.get("Balı yoxla")}
          back={"/"}
          msg={true}
          grid={true}
        />
        <div
          ref={(el) => {
            if (el) {
              el.parentElement.style.background = "#fff";
            }
          }}
        >
          <div className="Point">
            <div className="point__content">
              {license ? (
                <div className="row w-100">
                  <div className="col-md-6 mb-md-0 mb-4">
                    <button
                      className="custom-btn default-btn mb-2"
                      onClick={() => {
                        this.setState({
                          series: "",
                          license: null,
                        });
                      }}
                    >
                      <i className="feather feather-arrow-left"></i>
                    </button>
                  </div>

                  <div className="col-md-12">
                    <ContentLicense object={license} canPay />
                  </div>
                </div>
              ) : (
                <>
                  <form onSubmit={this.onSubmit} className="mb-5">
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label className="custom-different-label">
                          {translate.get("Seriya №-si")}
                        </label>
                        <input
                          required
                          type="text"
                          className="custom-different-input"
                          placeholder={"XXXXXXXX"}
                          value={series}
                          onChange={(e) => {
                            this.setState({ series: e.target.value });
                          }}
                        />
                      </div>

                      <div className="col-md-12">
                        <label className="custom-different-label">
                          {translate.get("Etibarlıdır")}
                        </label>
                      </div>

                      <div className="col-md-4 mb-3">
                        <div className="select-container">
                          <select
                            required
                            className="custom-different-select"
                            value={expire_day}
                            onChange={(e) => {
                              this.setState({ expire_day: e.target.value });
                            }}
                          >
                            <option value="" disabled>
                              {translate.get("Gün")}
                            </option>
                            {days.map((d, i) => (
                              <option key={i} value={d}>
                                {d < 10 ? `0${d}` : d}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 mb-3">
                        <div className="select-container">
                          <select
                            required
                            className="custom-different-select"
                            value={expire_month}
                            onChange={(e) => {
                              this.setState({
                                expire_month: e.target.value,
                              });
                              this.loadDaysbyMonth(e.target.value, expire_year);
                            }}
                          >
                            <option value="" disabled>
                              {translate.get("Ay")}
                            </option>
                            {months.map((m, i) => (
                              <option key={i} value={i + 1}>
                                {m}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-4 mb-3">
                        <div className="select-container">
                          <select
                            required
                            className="custom-different-select"
                            value={expire_year}
                            onChange={(e) => {
                              this.setState({ expire_year: e.target.value });
                              this.loadDaysbyMonth(
                                expire_month,
                                e.target.value
                              );
                            }}
                          >
                            <option value="" disabled>
                              {translate.get("İl")}
                            </option>
                            {years.map((y, i) => (
                              <option key={i} value={y}>
                                {y}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      {/*<div className="col-md-12">*/}
                      {/*  <label className="custom-different-label">*/}
                      {/*    {translate.get('Doğum tarixi')}*/}
                      {/*  </label>*/}
                      {/*</div>*/}

                      {/*<div className="col-md-4 mb-3">*/}
                      {/*  <div className="select-container">*/}
                      {/*    <select*/}
                      {/*      required*/}
                      {/*      className="custom-different-select"*/}
                      {/*      value={birth_day}*/}
                      {/*      onChange={e => {*/}
                      {/*        this.setState({birth_day: e.target.value});*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <option value="" disabled>*/}
                      {/*        {translate.get('Gün')}*/}
                      {/*      </option>*/}
                      {/*      {days.map((d, i) => (*/}
                      {/*        <option key={i} value={d}>*/}
                      {/*          {d < 10 ? `0${d}` : d}*/}
                      {/*        </option>*/}
                      {/*      ))}*/}
                      {/*    </select>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      {/*<div className="col-md-4 mb-3">*/}
                      {/*  <div className="select-container">*/}
                      {/*    <select*/}
                      {/*      required*/}
                      {/*      className="custom-different-select"*/}
                      {/*      value={birth_month}*/}
                      {/*      onChange={e => {*/}
                      {/*        this.setState({*/}
                      {/*          birth_month: e.target.value,*/}
                      {/*        });*/}
                      {/*        this.loadDaysbyMonth(e.target.value,*/}
                      {/*          birth_year);*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <option value="" disabled>*/}
                      {/*        {translate.get('Ay')}*/}
                      {/*      </option>*/}
                      {/*      {months.map((m, i) => (*/}
                      {/*        <option key={i} value={i + 1}>*/}
                      {/*          {m}*/}
                      {/*        </option>*/}
                      {/*      ))}*/}
                      {/*    </select>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      {/*<div className="col-md-4 mb-3">*/}
                      {/*  <div className="select-container">*/}
                      {/*    <select*/}
                      {/*      required*/}
                      {/*      className="custom-different-select"*/}
                      {/*      value={birth_year}*/}
                      {/*      onChange={e => {*/}
                      {/*        this.setState({birth_year: e.target.value});*/}
                      {/*        this.loadDaysbyMonth(birth_month,*/}
                      {/*          e.target.value);*/}
                      {/*      }}*/}
                      {/*    >*/}
                      {/*      <option value="" disabled>*/}
                      {/*        {translate.get('İl')}*/}
                      {/*      </option>*/}
                      {/*      {years.map((y, i) => (*/}
                      {/*        <option key={i} value={y}>*/}
                      {/*          {y}*/}
                      {/*        </option>*/}
                      {/*      ))}*/}
                      {/*    </select>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="col-md-12">
                        <button type="submit" className="custom-btn w-100 mt-1">
                          {isLoading ? <Icon /> : translate.get("Yoxla")}
                        </button>
                      </div>
                    </div>
                    <div className="mt-2" />
                  </form>
                  <Warn
                    desc={
                      "Sürücülük vəsiqəsində olan balları yoxlamaq üçün , sürücülük vəsiqəsin seriya, nömrəsi və vəsiqənin bitmə tarixini yazıb cərimə balları barədə məlumatı oyrənə bilərsiniz."
                    }
                    br={"#F3EBCF"}
                    warn={true}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
