import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../../lib";
import $ from "jquery";

export class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.type
    };

    $(document).on("click", e => {
      if (
        !$(".custom__modal").is(e.target) &&
        $(".custom__modal").has(e.target).length === 0
      ) {
        this.onClose();
      }
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    let { type } = this.state;

    return (
      <div className="custom__modal-container">
        <div className="custom__modal">
          <div className="custom__modal-header">
            <div className="modal__title">
              {type === "info"
                ? translate.get("Məlumat")
                : translate.get("Əlaqə")}
            </div>
            <button
              className="close-btn feather feather-x"
              onClick={this.onClose}
            ></button>
          </div>
          <div className="custom__modal-body">
            <div className="modal_content">
              {type === "info" ? (
                <div className="modal__info-content">
                  <h4 className="text-center mb-4">
                    SMSRadarın üstünlükləri nədir?
                  </h4>
                  <ul>
                    <li>
                      <b>1.</b>
                      Avtomobiliniz radara düşəndə və ya Digər qayda pozuntusu
                      edəndə anında mobil nömrənizə SMS-lə göndərilir.
                    </li>
                    <li>
                      <b>2.</b>
                      Qərarsız protokollar Qərarlı olanda sizə SMS-lə
                      göndərilir.
                    </li>
                    <li>
                      <b>3.</b>Protokol ödəniləndə elektron qəbz göndərilir.
                    </li>
                    <li>
                      <b>4.</b>Sual-Cavab bölməsində pulsuz huquqi yardım.
                    </li>
                    <li>
                      <b>5.</b>Texniki baxışın bitmə vaxtı göndərilir.
                    </li>
                    <li>
                      <b>6.</b>
                      Həbsin olub olmamasını göstərir və SMS-lə göndərilir.
                    </li>
                  </ul>
                  <p>
                    Xidmətə qoşulan Azercell, Bakcell və Nar Mobile abonentleri
                    YHQ-nı pozarken Qayda Pozuntusunun vaxtı və cərimə haqqında
                    ətraflı məlumat SMS-lə abonentin qeydiyyatdan keçdiyi mobil
                    nömrəyə göndəriləcək.
                  </p>
                  <p>Xidmətə qoşulma və istifadəsi:</p>
                  <b>Addım 1</b>
                  <p>
                    SMSRadar xidmətinə qoşulmaq istəyən Azercell, Bakcell və Nar
                    Mobile abonentləri cərimə məlumatlarını əldə etmək istədiyi
                    nəqliyyat vasitəsinin nömrəsini (misal: 00XX000) SMS-le
                    5666-a göndərir.
                  </p>
                  <b>Addım-2</b>
                  <p>
                    SMSRadar xidmətinə məxsus qısa nömrədən gələn avtomatik
                    cavab mesajının tələbi olaraq həmin avtomobilin Texniki
                    Pasport nömrəsini (misal: AA000000) SMS-lə 5666-a
                    göndərməlidir.
                  </p>
                  <p>
                    <b>Aylıq abunə haqqı:</b> 1 AZN (ƏDV daxil) Abonent
                    xidmətdən imtina etmək üçün stop maşının nömrəsi yazıb
                    SMS-lə 5666-a göndərməlidir.
                  </p>
                </div>
              ) : (
                <div className="modal__call-content">
                  <h4 className="text-center mb-4">Mobilex MMC</h4>
                  <ul>
                    <li>
                      <b>İş vaxtı:</b>həftə içi 5 gün saat: 10:00 dan 18:00 dək.
                    </li>
                    <li>
                      <b>Telefon:</b>
                      <Link target="blank" to={{ pathname: "tel:1114" }}>
                        1114
                      </Link>
                    </li>
                    <li>
                      <b>E-mail:</b>info@smsradar.az
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
