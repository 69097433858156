import React from "react";
import { translate } from "../../../lib";
import $ from "jquery";

export class Asset extends React.Component {
  constructor(props) {
    super(props);

    $(document).on("click", e => {
      if (
        !$(".image-modal").is(e.target) &&
        $(".image-modal").has(e.target).length === 0
      ) {
        this.onClose();
      }
    });
  }

  onClose = () => {
    this.props.onClose();
  };

  render() {
    return (
      <div className="custom__modal-container asset_modal">
        <div className="custom__modal image-modal">
          <div className="custom__modal-header">
            <div className="modal__title">
              {translate.get("Şəkil və video")}
            </div>
            <button
              className="close-btn feather feather-x"
              onClick={this.onClose}
            ></button>
          </div>
          <div className="custom__modal-body">
            {this.props.video ? (
              <video width="750" height="500" controls>
                <source src={this.props.fileSrc} type="video/mp4" />
              </video>
            ) : (
              <img alt="" src={this.props.fileSrc} />
            )}
          </div>
        </div>
      </div>
    );
  }
}
